import { Box, Flex, Grid, Button, Spinner, Text } from "@chakra-ui/react";
import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";
import { InputComponent } from "../../../components/Widgets/InputComponent";
import { useContext, useEffect, useState } from "react";
import { changeFirebaseAuthEmail } from "../../Login/index.js";
import { UserContext } from "../../../context";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { isGoogleUser } from "../../../functions/isGoogleUser";
import { unlink } from "firebase/auth";
import { useTranslation } from "react-i18next";

const ChangeEmail = () => {
  const { t } = useTranslation();

  const [state] = useContext(UserContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [repeatEmail, setRepeatEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [passwordOK, setPasswordOK] = useState(false);
  const [loading, setLoading] = useState(false);

  const validateEmail = (inputEmail) => {
    // Regular expression for a basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the input email matches the regex pattern
    const isValid = emailRegex.test(inputEmail);

    return isValid;
  };

  useEffect(() => {
    if (
      validateEmail(email) &&
      email === repeatEmail &&
      oldPassword.length >= 8 &&
      state.user.email
    ) {
      setPasswordOK(true);
    } else {
      setPasswordOK(false);
    }
  }, [email, repeatEmail, oldPassword, state.user.email]);

  return (
    <Grid width={["100%", "100%", "600px"]} gap={5}>
      {state.user && isGoogleUser(state.user) ? (
        <WidgetWrapper
          child={
            <Flex flexDirection={"column"}>
              <Text color={"#292929"} fontWeight={"semibold"}>
                {t("removeGoogleFromAccount")}{" "}
              </Text>
              <Text color={"#292929"}>{t("removeGoogleFromAccountText")}</Text>
              <Box>
                <Button
                  marginTop={"20px"}
                  bg={"#5138EE"}
                  color={"white"}
                  onClick={async () => {
                    await unlink(state.user, "google.com");
                    toast.success(t("removeGoogleFromAccountSuccessEmail"));
                    navigate("updatera-email");
                  }}
                >
                  {t("removeGoogleFromAccount")}
                </Button>
              </Box>
            </Flex>
          }
        ></WidgetWrapper>
      ) : (
        <WidgetWrapper
          child={
            <Flex flexDirection={"column"} width={"100%"}>
              <form
                style={{ width: "100%" }}
                onSubmit={async (e) => {
                  e.preventDefault(e);
                  setLoading(true);
                  const response = await changeFirebaseAuthEmail(
                    state.user.email,
                    oldPassword,
                    email
                  );
                  if (response.status !== 200) {
                    toast.error(response.error);
                  } else {
                    toast.success(response.message);
                    navigate("");
                  }
                  setLoading(false);
                }}
              >
                <Flex flexDirection={"column"} gap={4}>
                  <InputComponent
                    toolTip={true}
                    toolTipText={t("newEmailChange")}
                    placeholder={t("newEmailExample")}
                    label={t("newEmailLabel")}
                    setState={setEmail}
                    value={email}
                    type={"text"}
                  />
                  <InputComponent
                    toolTip={true}
                    toolTipText={t("repeatNewEmailToolTip")}
                    placeholder={t("newEmailExample")}
                    label={t("repeatNewEmailLabel")}
                    setState={setRepeatEmail}
                    value={repeatEmail}
                    type={"text"}
                  />
                  <InputComponent
                    toolTip={true}
                    toolTipText={t("usePassworToConfirm")}
                    placeholder={t("password")}
                    label={t("passwordNeeded")}
                    setState={setOldPassword}
                    value={oldPassword}
                    type={"password"}
                    showPassword={true}
                  />
                  <Box>
                    <Button
                      type="submit"
                      color={"white"}
                      fontWeight={"bold"}
                      isDisabled={!loading && passwordOK ? false : true}
                      bg={"#5138EE"}
                    >
                      {loading ? <Spinner /> : t("changeEmail")}
                    </Button>
                  </Box>
                </Flex>
              </form>
            </Flex>
          }
        ></WidgetWrapper>
      )}
    </Grid>
  );
};

export default ChangeEmail;
