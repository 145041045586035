import { Grid, Flex, Text, Button, Checkbox } from "@chakra-ui/react";
import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";

import { UserContext } from "../../../context/index.js";

import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { removeAccount } from "../../../calls";
import { useTranslation } from "react-i18next";

const DeleteAccount = () => {
  const { t } = useTranslation();

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [state] = useContext(UserContext);
  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      color={"white"}
      width={["100%", "100%", "70%"]}
      gap={"20px"}
    >
      <WidgetWrapper
        child={
          <Flex flexDirection={"column"} gap={3}>
            <Text fontSize={"20px"}>{t("deleteAccountTitle")}</Text>
            <Text fontSize={"14px"}>{t("deleteAccountText")}</Text>
            <Flex>
              <Checkbox
                isChecked={isCheckboxChecked}
                onChange={handleCheckboxChange}
              >
                {t("deleteAccountAccept")}
              </Checkbox>
              <Button
                color={"white"}
                bg={"#ee3838"}
                marginLeft={"auto"}
                isDisabled={!isCheckboxChecked}
                onClick={async () => {
                  await removeAccount(() => state.user.getIdToken());
                  navigate("/");
                }}
              >
                {t("removeAccount")}
              </Button>
            </Flex>
          </Flex>
        }
      ></WidgetWrapper>
    </Grid>
  );
};

export default DeleteAccount;
