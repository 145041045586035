import { Grid, Box, Flex, Spinner } from "@chakra-ui/react";
import WidgetWrapper from "../../../../components/Widgets/WidgetWrapper";
import { InputComponent } from "../../../../components/Widgets/InputComponent";

import QrGenerator from "../../../../components/QrGenerator";
import SelectionWidget from "../../../../components/Widgets/SelectionWidget";
import { useTranslation } from "react-i18next";

const InfoQR = ({ qrSettings, setqrSettings, loadImage }) => {
  const { t } = useTranslation();

  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      color={"white"}
      gridTemplateColumns={"2fr 1fr"}
      gap={"20px"}
    >
      <Grid gap={"20px"}>
        <WidgetWrapper
          className={"backgroundGradient"}
          child={
            <Box color={"white"} fontSize={"18px"} fontWeight={"bold"}>
              {qrSettings.qrName}
            </Box>
          }
        />
        <WidgetWrapper
          child={
            <Box w={"100%"}>
              <InputComponent
                toolTip={true}
                toolTipText={"Här har namnet på din QR-kod."}
                readOnly={true}
                label={t("qrName")}
                value={qrSettings}
                keyValue={"qrName"}
                type={"text"}
              />
              <InputComponent
                toolTip={true}
                toolTipText={t("createQRLinkTooltip")}
                readOnly={true}
                label={t("editQRLinkLabel")}
                value={qrSettings}
                keyValue={"targetUrl"}
                type={"text"}
              />
              <SelectionWidget
                readOnly={true}
                toolTip={true}
                toolTipText={t("createQRTagsTooltip")}
                payedFeature={false}
                label={t("qrTagLabel")}
                qrSettings={qrSettings}
              />
              <InputComponent
                toolTip={true}
                toolTipText={t("createQRDate")}
                readOnly={true}
                label={t("created")}
                value={qrSettings}
                keyValue={"created_at"}
                type={"text"}
              />
              <InputComponent
                toolTip={true}
                toolTipText={t("qrID")}
                readOnly={true}
                label={"QR ID"}
                value={qrSettings}
                keyValue={"qrID"}
                type={"text"}
              />
            </Box>
          }
        />
      </Grid>
      <Grid gap={"20px"} marginBottom={"auto"}>
        <WidgetWrapper
          center={true}
          child={
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              {loadImage ? (
                <Flex alignItems={"center"} height={"230px"}>
                  <Spinner></Spinner>
                </Flex>
              ) : (
                <QrGenerator
                  loadImage={loadImage}
                  qrSettings={qrSettings}
                  setqrSettings={setqrSettings}
                />
              )}
            </Flex>
          }
        />
      </Grid>
    </Grid>
  );
};

export default InfoQR;
