import { Grid, GridItem, Flex, Text, Divider } from "@chakra-ui/react";
import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";
import {
  MdOutlineQrCode,
  MdOutlineSafetyCheck,
  MdOutlinePayment,
  MdChatBubbleOutline,
  MdBusiness,
  MdHealthAndSafety,
  MdQuestionMark,
  MdQuestionAnswer,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Support = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const questionSection = [
    {
      title: t("dataAndSecruity"),
      underTitle: t("dataAndSecruityText"),
      icon: <MdHealthAndSafety size={50} />,
      openWebsite: process.env.REACT_APP_STATIC_STIE + "/privacy",
    },
    {
      title: t("ContactUs"),
      underTitle: t("weHelpYou"),
      icon: <MdQuestionAnswer size={50} />,
      redirect: "/support/kontakt",
      closeDown: false,
    },
    {
      title: "Komma igång",
      underTitle: "Lär dig att skapa SMARTA QR-koder med Dinqr",
      icon: <MdOutlineQrCode size={50} />,
      redirect: "/support/borja-skapa",
      closeDown: true,
    },
    {
      title: "GDPR",
      underTitle: "Har du hur vi spara data",
      icon: <MdHealthAndSafety size={50} />,
      redirect: "/support/gdpr",
      closeDown: true,
    },
    {
      title: "Säkerhet",
      underTitle: "Hur vi hanterar din säkerhet",
      icon: <MdOutlineSafetyCheck size={50} />,
      redirect: "/support/sakerhet",
      closeDown: true,
    },
    {
      title: "Betalning",
      underTitle: "Hur vi hanterar dina betalningar",
      icon: <MdOutlinePayment size={50} />,
      redirect: "/support/betalning",
      closeDown: true,
    },
    {
      title: "F.A.Q",
      underTitle: t("FAQText"),
      icon: <MdChatBubbleOutline size={50} />,
      closeDown: false,
      openWebsite: process.env.REACT_APP_STATIC_STIE + "/faq",
    },
    {
      title: t("busniess"),
      underTitle: t("busniessText"),
      icon: <MdBusiness size={50} />,
      redirect: "/support/boka",
      closeDown: false,
    },
  ];
  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      w={"100%"}
      color={"white"}
      gridTemplateColumns={"1fr"}
      gap={"20px"}
      width={"100%"}
      padding={"0px 0px 20px 0px"}
    >
      <Grid gap={"20px"} marginBottom={"auto"}>
        <GridItem>
          <WidgetWrapper
            className="backgroundGradient"
            child={
              <Flex color={"white"} width={"100%"} flexDirection={"column"}>
                <Text fontWeight={"bold"} fontSize={"22px"}>
                  {t("supportTitle")}{" "}
                </Text>
                <Text>
                  {t("supportText")}{" "}
                  <span style={{ fontWeight: "bold" }}>{t("dinqrEmail")}</span>{" "}
                  {t("weHelpYou2")}
                </Text>
              </Flex>
            }
          />
        </GridItem>
        <GridItem>
          <Flex color={"#292929"} width={"100%"} flexDirection={"column"}>
            <Text fontWeight={"bold"} fontSize={"22px"}>
              {t("weHelpYou3")}{" "}
            </Text>
            <Text>{t("findAnswerHere")}</Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex
            flexWrap={["nowrap", "nowrap", "wrap"]}
            flexDirection={["column", "column", "row"]}
            gap={5}
          >
            {questionSection.map((child) => {
              if (child?.closeDown) return;
              return (
                <WidgetWrapper
                  closeDown={child.closeDown}
                  extraTextComponoent={
                    <Text color={"black"} fontWeight={"bold"} fontSize={"12px"}>
                      {t("commingSoon")}
                    </Text>
                  }
                  className="supportItems"
                  child={
                    <Flex
                      onClick={() => {
                        if (child.redirect) {
                          navigate(child.redirect);
                        } else if (child.openWebsite) {
                          window.open(child.openWebsite, "_blank");
                        }
                      }}
                      cursor={"pointer"}
                      width={["100%", "100%", "200px"]}
                      height={("auto", "auto", "150px")}
                      flexDirection={"column"}
                      color={"#292929"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={2}
                    >
                      {child.icon}
                      <Text
                        fontWeight={"bold"}
                        fontSize={"16px"}
                        color={"#062C8E"}
                        textAlign={"center"}
                      >
                        {child.title}
                      </Text>
                      <Divider />
                      <Text fontSize={"12px"} textAlign={"center"}>
                        {child.underTitle}
                      </Text>
                    </Flex>
                  }
                />
              );
            })}
          </Flex>
        </GridItem>
      </Grid>
    </Grid>
  );
};

export default Support;

/*onClick={() => navigate(child.redirect)} */
