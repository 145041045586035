import { useEffect, useState } from "react";
import { getContrastRatio } from "../functions/constractChecker.js";
import { Box, Text, Flex, Spinner } from "@chakra-ui/react";

import { QRCodeSVG } from "qrcode.react";

import { useTranslation } from "react-i18next";

const QrGenerator = ({ qrSettings, preview }) => {
  const [contrastChecker, setContrastChecker] = useState(false);
  const [logoSizeChecker, setLogoSizeChecker] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!qrSettings.color && qrSettings.background) return;
    const ccc = getContrastRatio(qrSettings.color, qrSettings.background);
    if (ccc < 8) {
      setContrastChecker(true);
    } else {
      setContrastChecker(false);
    }
  }, [qrSettings.color, qrSettings.background]);

  const LogoSizeCheckF = (l, ln, w, lw, p, lp) => {
    if (l > ln || w > lw || p > lp) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!qrSettings.logoName) {
      setLogoSizeChecker(false);
      return;
    }

    const { logoWidth, logoHeight, ecLevel } = qrSettings;
    if (ecLevel === "L") {
      setLogoSizeChecker(LogoSizeCheckF(logoWidth, 45, logoHeight, 45));
    } else if (ecLevel === "M") {
      setLogoSizeChecker(LogoSizeCheckF(logoWidth, 60, logoHeight, 60));
    } else if (ecLevel === "H") {
      setLogoSizeChecker(LogoSizeCheckF(logoWidth, 80, logoHeight, 80));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    qrSettings.logoName,
    qrSettings.logoHeight,
    qrSettings.logoWidth,
    qrSettings.logoPadding,
    qrSettings.ecLevel,
  ]);

  return (
    <>
      <Flex marginTop={"auto"} paddingTop={"50px"}>
        {qrSettings ? (
          <>
            {qrSettings.logoImage ? (
              <QRCodeSVG
                size={200}
                style={{ maxWidth: "100%" }}
                value={process.env.REACT_APP_PROXY_URL + "/" + qrSettings.qrID}
                imageSettings={{
                  src: qrSettings.logoImage,
                  height: qrSettings.logoHeight,
                  width: qrSettings.logoWidth,
                }}
                bgColor={qrSettings.background}
                fgColor={qrSettings.color}
                level={qrSettings.ecLevel}
              />
            ) : (
              <QRCodeSVG
                size={200}
                style={{ maxWidth: "100%" }}
                value={process.env.REACT_APP_PROXY_URL + "/" + qrSettings.qrID}
                bgColor={qrSettings.background}
                fgColor={qrSettings.color}
                level={qrSettings.ecLevel}
              />
            )}
          </>
        ) : (
          <Spinner></Spinner>
        )}
      </Flex>
      {preview && (
        <Text color={"#292929"} fontWeight={"bold"} fontSize={"14px"}>
          {t("preview")}
        </Text>
      )}

      <Flex
        flexDirection={"column"}
        gap={2}
        marginTop={"auto"}
        paddingTop={"50px"}
      >
        {contrastChecker && (
          <Text color="red" fontWeight="bold" fontSize="12px">
            {t("qrContrastTextWarning")}
          </Text>
        )}

        {!contrastChecker && logoSizeChecker && (
          <Box>
            <Text color="red" fontWeight="bold" fontSize="12px">
              {t("qrLogoTextWarning")}
              {qrSettings.ecLevel !== "H" ? t("qrLogoTextWarningEC") : "."}
            </Text>
          </Box>
        )}
      </Flex>
    </>
  );
};
export default QrGenerator;
