import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";
import { Grid, GridItem, Flex, Text } from "@chakra-ui/react";
import TicketForm from "../../../components/Tickets";

const Kontakt = () => {
  return (
    <Grid gap={"20px"} marginBottom={"auto"}>
      <GridItem>
        <WidgetWrapper child={<TicketForm />} />
      </GridItem>
    </Grid>
  );
};

export default Kontakt;
