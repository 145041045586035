import {
  Box,
  Divider,
  Text,
  Flex,
  Button,
  Link,
  Menu,
  MenuItem,
  MenuButton,
  IconButton,
  MenuList,
  Image,
} from "@chakra-ui/react";
import { UserContext } from "../context/index";
import { useContext, useState } from "react";
import { HamburgerIcon } from "@chakra-ui/icons";
import dinqrLogo from "../content/dinqr.svg";
import { useTranslation } from "react-i18next";
//Icon SVGs
import {
  CreateQRSVG,
  DashboardSVG,
  ProfileSVG,
  MyQR,
  Analys,
  OrgSVG,
} from "../components/icons";

import { useNavigate } from "react-router-dom";
import { logout } from "../pages/Login/index.js";
import { AiOutlineLogout } from "react-icons/ai";

/* eslint-disable */
const NavBar = () => {
  const { t } = useTranslation();

  const navbar = [
    {
      text: t("dashboard"),
      redirect: "/dashboard",
      activePaths: ["/dashboard"],
      icon: {
        active: <DashboardSVG color={"white"} />,
        inActive: <DashboardSVG color={"#516BB0"} />,
      },
    },
    {
      text: t("createQR"),
      redirect: "/skapa-qr",
      activePaths: ["/skapa-qr"],
      icon: {
        active: <CreateQRSVG color={"white"} />,
        inActive: <CreateQRSVG color={"#516BB0"} />,
      },
    },
    {
      text: t("myQR"),
      redirect: "/mina-qr",
      activePaths: ["/mina-qr"],
      icon: {
        active: <MyQR color={"white"} />,
        inActive: <MyQR color={"#516BB0"} />,
      },
    },
    {
      text: t("analyse"),
      redirect: "/analysera",
      activePaths: ["/analysera"],
      icon: {
        active: <Analys color={"white"} />,
        inActive: <Analys color={"#516BB0"} />,
      },
    },
    {
      text: t("myAccount"),
      redirect: "/konto",
      activePaths: ["/konto"],
      icon: {
        active: <ProfileSVG color={"white"} />,
        inActive: <ProfileSVG color={"#516BB0"} />,
      },
    },
    /* {
    text: "Organisation",
    redirect: "/organisation/allmant",
    activePaths: ["/organisation"],
    icon: {
      active: <OrgSVG color={"white"} />,
      inActive: <OrgSVG color={"#516BB0"} />,
    },
    role: ["pro", "enterprise"],
    orgRole: ["user", "admin", "superadmin"],
  }, */
  ];
  const [state, dispatch] = useContext(UserContext);
  const navigator = useNavigate();
  const [, setItemHover] = useState("/dashboard");

  return (
    <>
      {/*Mobile*/}
      <Flex display={["flex", "flex", "none"]}>
        <Flex
          alignItems={"center"}
          gap={2}
          justifyContent={"center"}
          p={"10px"}
          width={"100%"}
          bg={"white"}
          shadow={"lg"}
          zIndex={100}
        >
          <Image
            cursor={"pointer"}
            onClick={() => navigator("/dashboard")}
            w={"75px"}
            p={"5px"}
            src={dinqrLogo}
          ></Image>
          <Box marginLeft={"auto"}>
            <Menu>
              <MenuButton
                as={IconButton}
                bg={"#062C8E"}
                aria-label="Options"
                icon={<HamburgerIcon color={"white"} />}
                variant="outline"
              />
              <MenuList>
                {navbar.map((child, index) => {
                  if (child.role && !child.role.includes(state.stripeRole))
                    return;
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => navigator(child.redirect)}
                      icon={
                        <Box h={"15px"} w={"15px"}>
                          {child.icon.inActive}
                        </Box>
                      }
                    >
                      {child.text}
                    </MenuItem>
                  );
                })}
                <MenuItem
                  key={"logga ut"}
                  onClick={() => {
                    dispatch({});
                    logout(state.user);
                    navigator("/");
                  }}
                  icon={
                    <Flex h={"15px"} marginY={"auto"}>
                      <AiOutlineLogout size={18} />
                    </Flex>
                  }
                >
                  {t("logout")}
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      </Flex>
      {/*Desktop*/}
      <Flex
        display={["none", "none", "flex"]}
        w={"100%"}
        h={"100vh"}
        bg={"white"}
        padding={"20px 20px"}
        flexDirection={"column"}
      >
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          padding={"0px 0px 50px 0px"}
          gap={2}
        >
          <Image
            cursor={"pointer"}
            onClick={() => navigator("/dashboard")}
            w={"75px"}
            src={dinqrLogo}
          ></Image>
          <Divider />
          {/* <Text fontWeight={"bold"} className="gradientForText">
            {state.stripeRole} 
          </Text> */}
        </Flex>
        <Flex flexDirection={"column"} gap={3}>
          {navbar.map((child, index) => {
            //FIX THIS
            const isActive = child.activePaths.some((x) =>
              window.location.pathname.startsWith(x)
            );
            if (child.role && !child.role.includes(state.stripeRole)) return;
            return (
              <Box
                key={index}
                onMouseEnter={() => setItemHover(child.redirect)}
                onMouseLeave={() => setItemHover(false)}
              >
                <Button
                  key={index}
                  onClick={() => navigator(child.redirect)}
                  w={"100%"}
                  justifyContent={"left"}
                  backgroundColor={isActive ? "#062C8E" : "white"}
                >
                  <Box
                    h={"25px"}
                    w={"25px"}
                    marginRight={"10px"}
                    opacity={isActive ? 1 : 0.8}
                  >
                    {isActive ? child.icon.active : child.icon.inActive}
                  </Box>
                  <Text
                    whiteSpace={"nowrap"}
                    fontSize={"12px"}
                    color={isActive ? "white" : "#516BB0"}
                    fontWeight={"bold"}
                  >
                    {child.text}
                  </Text>
                </Button>
              </Box>
            );
          })}
        </Flex>
        <Flex
          hidden={true}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Flex
            className="backgroundGradientForCard"
            backgroundSize={"cover"}
            color={"white"}
            padding={"20px"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            rounded={"lg"}
            gap={3}
          >
            <Text fontSize={"16px"} textAlign={"center"}>
              {t("tinyCompany")}
            </Text>
            <Text fontSize={"12px"} textAlign={"center"}>
              {t("upgradePlanFrom")}{" "}
            </Text>
            <Button color={"#062C8D"} fontSize={"12px"} fontWeight={"bold"}>
              {t("upgradePlan")}
            </Button>
          </Flex>
        </Flex>
        <Flex
          gap={2}
          marginTop={"auto"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Link
            onClick={() => navigator("/support")}
            color={"#062C8E"}
            fontWeight={"bold"}
          >
            {t("support")}
          </Link>
          <Divider />
          <Button
            onClick={() => {
              dispatch({});
              logout();
              navigator("/");
            }}
            bg={"#none"}
            _hover={"none"}
            fontWeight={"normal"}
          >
            {t("logout")}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default NavBar;
