import { Text, Button, Flex, Box } from "@chakra-ui/react";

import WidgetWrapper from "./Widgets/WidgetWrapper.jsx";

import { useLocation, useNavigate } from "react-router-dom";
import { Fragment } from "react";

const SystemBar = ({ navbar, pathname, orgInfo, state }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Flex
      gap={"10px"}
      h={"fit-content"}
      fontSize={"14px"}
      width={"100%"}
      maxW={"calc(100vw - 20px)"}
      overflowX={"scroll"}
    >
      {navbar.map((child, index) => {
        let redirect;
        if (!child.redirect) {
          redirect = pathname;
        } else {
          redirect = pathname + "/" + child.redirect;
        }
        if (child.orgIsCreated && !orgInfo) return <Fragment key={index} />;

        if (
          state?.organization?.owner &&
          state.organization.owner !== state.user.uid &&
          child.notForOrgMembers
        )
          return <Fragment key={index} />;
        if (
          child.blockRoles &&
          child.blockRoles.includes(state?.organization?.role)
        )
          return <Fragment key={index} />;
        return (
          <Flex key={index} {...child.extraCSSContainer}>
            <WidgetWrapper
              noPadding={true}
              child={
                <>
                  <Button
                    onClick={() => navigate(child.redirect)}
                    gap={2}
                    justifyContent={"left"}
                    bg={location.pathname !== redirect ? "white" : "#5138EE"}
                    {...child.extraCSSButton}
                  >
                    {location.pathname !== redirect
                      ? child.icon.active
                      : child.icon.inActive}
                    <Text
                      color={location.pathname !== redirect ? "black" : "white"}
                      fontWeight={"bold"}
                      {...child.extraCSSText}
                    >
                      {child.text}
                    </Text>
                  </Button>
                </>
              }
            />
          </Flex>
        );
      })}
    </Flex>
  );
};

export default SystemBar;
