const checkIfValidEmails = (emailList) => {
  // Regular expression pattern for validating email addresses
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  for (let email of emailList) {
    // If the email is empty, move to the next iteration
    if (email.trim() === "") {
      continue;
    }

    // Check if the email matches the pattern
    if (!emailPattern.test(email)) {
      return false; // If any email is invalid, return false
    }
  }

  return true; // All emails are valid
};

export default checkIfValidEmails;
