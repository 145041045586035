import axios from "axios";

const countTotalEventsfromBigquery = async (getIdToken) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + "/countTotalEventsfromBigquery", {
      headers: { Authorization: await getIdToken() },
    })
    .then((response) => {
      return response.data.data;
    });
};

const getStripeProducts = async (getIdToken) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + "/products", {
      headers: { Authorization: await getIdToken() },
    })
    .then((response) => {
      return response.data;
    });
};

const getQRsByUserID = async (getIdToken) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + "/getQRsByUserID", {
      headers: { Authorization: await getIdToken() },
    })
    .then((response) => {
      return response.data.data.length;
    });
};

const generateQRID = async (getIdToken) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + "/generateQRID", {
      headers: { Authorization: await getIdToken() },
    })
    .then((response) => {
      return response.data.qrID;
    });
};

const createQRCode = async (getIdToken, qrSettings) => {
  if (!qrSettings.qrName || !qrSettings.targetUrl) {
    console.log("Input missing.. will add on for user aswell");
    return;
  }

  return await axios.put(
    process.env.REACT_APP_API_ENDPOINT + "/createQRCode",
    qrSettings,
    {
      headers: { Authorization: await getIdToken() },
    }
  );
};

const countTotalEventsFromBigqueryByTime = async (getIdToken, queryOptions) => {
  return await axios
    .get(
      process.env.REACT_APP_API_ENDPOINT +
        `/countTotalEventsFromBigqueryByTime/${queryOptions.interval}/${queryOptions.timespan}`,
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((response) => {
      return response.data.data;
    });
};

const countEventsfromBigqueryByTime = async (
  getIdToken,
  queryOptions,
  selectedQR
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await Promise.all(
        selectedQR.map(async (child) => {
          const response = await axios.get(
            process.env.REACT_APP_API_ENDPOINT +
              `/countEventsfromBigqueryByTime/${child.qrID}/${queryOptions.interval}/${queryOptions.timespan}`,
            {
              headers: { Authorization: await getIdToken() },
            }
          );
          return { name: child.qrName, data: response.data.data.reverse() }; // assuming you want to return the data from the API call
        })
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

const deactivateQRCode = async (getIdToken, qrSettings) => {
  return await axios
    .patch(
      process.env.REACT_APP_API_ENDPOINT +
        "/deactivateQRCode/" +
        qrSettings.qrID,
      {},
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((response) => {
      return response;
    });
};

const editQRCode = async (getIdToken, qrSettings) => {
  return await axios
    .patch(
      process.env.REACT_APP_API_ENDPOINT + "/editQRCode",
      { ...qrSettings },
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const getQRbyID = async (getIdToken, qrId) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + "/getQRbyID/" + qrId, {
      headers: { Authorization: await getIdToken() },
    })
    .then((data) => {
      return data;
    });
};

const readEventsfromBigquery = async (getIdToken, qrId) => {
  return await axios
    .get(
      process.env.REACT_APP_API_ENDPOINT + "/readEventsfromBigquery/" + qrId,
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const generateSignedUrl = async (getIdToken, logoImage) => {
  return await axios
    .put(
      process.env.REACT_APP_API_ENDPOINT + "/generateSignedUrl",
      { fileName: logoImage.name },
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const getUserImages = async (getIdToken) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + "/userImages", {
      headers: { Authorization: await getIdToken() },
    })
    .then((data) => {
      return data;
    });
};

const getUserImage = async (getIdToken, fileName) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + "/getUserImage/" + fileName, {
      headers: { Authorization: await getIdToken() },
    })
    .then((data) => {
      return data;
    });
};

const deleteUserImage = async (getIdToken, fileName) => {
  return await axios
    .delete(process.env.REACT_APP_API_ENDPOINT + "/userImage/" + fileName, {
      headers: { Authorization: await getIdToken() },
    })
    .then((data) => {
      return data;
    });
};

const setUserClaimsFree = async (getIdToken) => {
  return await axios
    .put(
      process.env.REACT_APP_API_ENDPOINT + "/setUserClaimsFree",
      {},
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const setUserClaimLang = async (getIdToken, langCode) => {
  return await axios
    .put(
      process.env.REACT_APP_API_ENDPOINT + "/setUserClaimLang",
      { lang: langCode },
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const setUserClaimsBeta = async (getIdToken) => {
  return await axios
    .put(
      process.env.REACT_APP_API_ENDPOINT + "/setUserClaimsBeta",
      {},
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const checkUserAmountOfQRCodes = async (getIdToken) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + "/checkUserAmountOfQRCodes", {
      headers: { Authorization: await getIdToken() },
    })
    .then((data) => {
      return data;
    });
};

const countoTotalLast30_7_1Days = async (getIdToken, qrId) => {
  return await axios
    .get(
      process.env.REACT_APP_API_ENDPOINT + `/countoTotalLast30_7_1Days/${qrId}`,
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const getUserQRwithLogo = async (getIdToken, logoName) => {
  return await axios
    .get(
      process.env.REACT_APP_API_ENDPOINT + `/getUserQRwithLogo/${logoName}`,
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const createDesignTemplate = async (getIdToken, qrSettings) => {
  return await axios
    .put(
      process.env.REACT_APP_API_ENDPOINT + `/createDesignTemplate`,
      { ...qrSettings },
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const getAllUserDesignTemplates = async (getIdToken) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + `/getAllUserDesignTemplates`, {
      headers: { Authorization: await getIdToken() },
    })
    .then((data) => {
      return data;
    });
};
// Organisation
const createLogEvent = async (getIdToken, data) => {
  return await axios.post(
    process.env.REACT_APP_API_ENDPOINT + "/createLogEvent",
    data,
    {
      headers: { Authorization: await getIdToken() },
    }
  );
};

const createOrganisation = async (getIdToken, organisation) => {
  return await axios
    .put(
      process.env.REACT_APP_API_ENDPOINT + "/createOrganisation",
      { organisationName: organisation },
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const deleteUserDesignTemplate = async (getIdToken, designID) => {
  return await axios.delete(
    process.env.REACT_APP_API_ENDPOINT +
      `/deleteUserDesignTemplate/` +
      designID,
    {
      headers: { Authorization: await getIdToken() },
    }
  );
};

const addUserToOrg = async (getIdToken, data) => {
  return await axios
    .put(
      process.env.REACT_APP_API_ENDPOINT + "/addUserToOrg",
      { userID: data.id, permission: data.permission },
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const updateUserPermission = async (getIdToken, id, permission) => {
  return await axios
    .patch(
      process.env.REACT_APP_API_ENDPOINT + "/updateUserPermission",
      { userID: id, permission: permission },
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const removeUserFromOrg = async (getIdToken, id) => {
  return await axios
    .delete(process.env.REACT_APP_API_ENDPOINT + `/removeUserFromOrg/${id}`, {
      headers: { Authorization: await getIdToken() },
    })
    .then((data) => {
      return data;
    });
};

const transferOwnership = async (getIdToken, data) => {
  return await axios
    .put(
      process.env.REACT_APP_API_ENDPOINT + "/transferOwnership",
      { userID: data.id },
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const removeUserClaims = async (getIdToken) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + "/removeUserClaims", {
      headers: { Authorization: await getIdToken() },
    })
    .then((data) => {
      return data;
    });
};

const getOriginisationInformation = async (getIdToken) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + "/getOriginisationInformation", {
      headers: { Authorization: await getIdToken() },
    })
    .then((data) => {
      return data;
    });
};

const GenerateAccountFromEmail = async (getIdToken, email, role) => {
  return await axios
    .put(
      process.env.REACT_APP_API_ENDPOINT + "/GenerateAccountFromEmail",
      { email: email, role: role },
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const removeAccount = async (getIdToken) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + "/removeAccount", {
      headers: { Authorization: await getIdToken() },
    })
    .then((data) => {
      return data;
    });
};

const sendFeedback = async (getIdToken, feedback, type) => {
  return await axios
    .put(
      process.env.REACT_APP_API_ENDPOINT + "/feedback",
      { feedback: feedback, type: type },
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const sendTicket = async (getIdToken, data) => {
  return await axios
    .put(process.env.REACT_APP_API_ENDPOINT + "/addTicket", data, {
      headers: { Authorization: await getIdToken() },
    })
    .then((data) => {
      return data;
    });
};

const getUserBillingPortalLink = async (getIdToken) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + "/getUserBillingPortalLink", {
      headers: { Authorization: await getIdToken() },
    })
    .then((data) => {
      return data;
    });
};

const getUserCheckoutLink = async (getIdToken, checkoutInfo) => {
  console.log(checkoutInfo);
  return await axios
    .put(
      process.env.REACT_APP_API_ENDPOINT + "/getUserCheckoutLink",
      { ...checkoutInfo },
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const getUserSubscription = async (getIdToken) => {
  return await axios
    .put(process.env.REACT_APP_API_ENDPOINT + "/getUserSubscription", {
      headers: { Authorization: await getIdToken() },
    })
    .then((data) => {
      return data;
    });
};

const removeOrganisation = async (getIdToken, id) => {
  return await axios
    .delete(process.env.REACT_APP_API_ENDPOINT + `/removeOrganisation`, {
      headers: { Authorization: await getIdToken() },
    })
    .then((data) => {
      return data;
    });
};

const getOwnerStripeRole = async (getIdToken) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + `/getOwnerStripeRole`, {
      headers: { Authorization: await getIdToken() },
    })
    .then((data) => {
      return data;
    });
};

const subscribeToEmail = async (getIdToken, subBool) => {
  return await axios
    .get(
      process.env.REACT_APP_API_ENDPOINT +
        `/subscribeToEmailList?iwantemails=${subBool}`,
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const purchasedProducts = async (getIdToken, productID) => {
  return await axios
    .get(
      process.env.REACT_APP_API_ENDPOINT +
        `/listPurchasedProducts/${productID}`,
      {
        headers: { Authorization: await getIdToken() },
      }
    )
    .then((data) => {
      return data;
    });
};

const listPurchasedProducts = async (getIdToken, stripeProductId) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + `/listPurchasedProducts/${stripeProductId}`, {
      headers: { Authorization: await getIdToken() },
    })
    .then((response) => {
      return response;
    });
};

const listAvailableProducts = async (getIdToken, stripeProductId) => {
  return await axios
    .get(process.env.REACT_APP_API_ENDPOINT + `/listAvailableProducts/${stripeProductId}`, {
      headers: { Authorization: await getIdToken() },
    })
    .then((response) => {
      return response;
    });
};

export {
  listAvailableProducts,
  listPurchasedProducts,
  sendFeedback,
  removeAccount,
  GenerateAccountFromEmail,
  getOriginisationInformation,
  deleteUserDesignTemplate,
  getAllUserDesignTemplates,
  createDesignTemplate,
  getUserQRwithLogo,
  countoTotalLast30_7_1Days,
  checkUserAmountOfQRCodes,
  setUserClaimsBeta,
  setUserClaimsFree,
  deleteUserImage,
  getUserImages,
  getUserImage,
  generateSignedUrl,
  countTotalEventsfromBigquery,
  getQRsByUserID,
  generateQRID,
  createQRCode,
  countTotalEventsFromBigqueryByTime,
  countEventsfromBigqueryByTime,
  deactivateQRCode,
  editQRCode,
  getQRbyID,
  readEventsfromBigquery,
  createOrganisation,
  addUserToOrg,
  updateUserPermission,
  removeUserFromOrg,
  transferOwnership,
  createLogEvent,
  removeUserClaims,
  getStripeProducts,
  getUserSubscription,
  getUserBillingPortalLink,
  getUserCheckoutLink,
  removeOrganisation,
  getOwnerStripeRole,
  sendTicket,
  setUserClaimLang,
  subscribeToEmail,
  purchasedProducts,
};
