import { Box, Grid, GridItem, Text, Divider, Link } from "@chakra-ui/react";
import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";

const GDPR = () => {
  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      w={"100%"}
      color={"white"}
      gridTemplateColumns={"1fr"}
      gap={"20px"}
      width={"100%"}
      padding={"0px 0px 20px 0px"}
    >
      <Grid gap={"20px"} marginBottom={"auto"}>
        <GridItem>
          <WidgetWrapper
            child={
              <Box>
                <Text fontSize="2xl" fontWeight="bold" mb={4}>
                  GDPR
                </Text>
                <Text>
                  På DinQR tar vi din integritet på största allvar och följer
                  bestämmelserna i Dataskyddsförordningen (GDPR). Denna sida
                  beskriver hur vi samlar in, använder och lagrar dina uppgifter
                  i enlighet med GDPR-lagstiftningen.
                </Text>
                <Divider my={4} />
                <Text fontSize="md" fontWeight="bold" mb={2}>
                  Insamling och Användning av Data
                </Text>
                <Text>
                  Vi samlar endast in nödvändig information som krävs för att
                  tillhandahålla våra tjänster. Det kan inkludera personlig
                  information såsom ditt namn, e-postadress och andra relevanta
                  detaljer. Vi använder denna information endast för att
                  leverera våra tjänster och förbättra din upplevelse på vår
                  webbplats.
                </Text>
                <Text my={4} fontSize="md" fontWeight="bold" mb={2}>
                  Lagring och Säkerhet
                </Text>
                <Text>
                  Dina data lagras säkert på våra servrar. Vi implementerar
                  branschstandard-säkerhetsåtgärder för att skydda din
                  information från obehörig åtkomst, avslöjande, ändring och
                  förstörelse.
                </Text>
                <Text my={4} fontSize="md" fontWeight="bold" mb={2}>
                  Cookies och Spårning
                </Text>
                <Text>
                  Vi kan använda cookies och liknande spårningstekniker för att
                  förbättra din webbläsarupplevelse. Du kan hantera dina
                  cookie-preferenser genom webbläsarinställningarna.
                </Text>
                <Text my={4} fontSize="md" fontWeight="bold" mb={2}>
                  Dina Rättigheter
                </Text>
                <Text>
                  Som användare har du rätt att få tillgång till, rätta till
                  eller ta bort dina personuppgifter. Om du har några frågor
                  eller funderingar angående dina uppgifter, vänligen kontakta
                  oss på <Link>dinqr-support@dinqr.se</Link>.
                </Text>
              </Box>
            }
          />
        </GridItem>
      </Grid>
    </Grid>
  );
};

export default GDPR;
