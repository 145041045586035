import { Box, Grid, GridItem, Flex } from "@chakra-ui/react";
import WidgetWrapper from "../../../../components/Widgets/WidgetWrapper";
import HighChart from "../../../../functions/toHighChart";
import Select from "react-select";

import ScoreWidget from "../../../../components/Widgets/ScoreWidget";

import qrScan from "../../../../content/icons/scan.svg";

import { useContext, useEffect, useState } from "react";
import { countoTotalLast30_7_1Days } from "../../../../calls";

import { UserContext } from "../../../../context";
import Options from "../../../../context/optionsChart";
import { useTranslation } from "react-i18next";

const InfoQR = ({ qrSettings }) => {
  const { t } = useTranslation();

  const [state] = useContext(UserContext);
  const [scoreWidget, setScoreWidget] = useState(false);

  const [timespan, setTimespan] = useState(false);
  const [interval, setInterval] = useState(false);
  const [timespanOptions, setTimeSpanOptions] = useState(false);
  const [intervalOptions, setIntervalOptions] = useState(false);

  useEffect(() => {
    setTimespan(Options(t)[0]);
    setInterval(Options(t)[0].interval[2]);

    setIntervalOptions(Options(t)[0].interval);
    setTimeSpanOptions(Options(t));
  }, [t]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await countoTotalLast30_7_1Days(
          () => state.user.getIdToken(),
          qrSettings.qrID
        );
        setScoreWidget(response.data.data[0]);
      } catch (error) {}
    };
    fetchData();
  }, [qrSettings.qrID, state.user]);

  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      color={"white"}
      padding={"0px 0px 20px 0px"}
      gap={"20px"}
      height={"100%"}
      width={"100%"}
    >
      <Grid gap="20px" width={"100%"}>
        <Flex
          gap="20px"
          width={"100%"}
          flexDirection={["column", "column", "row"]}
        >
          <WidgetWrapper
            payedFeature={false}
            child={
              <ScoreWidget
                text={t("qrScansToday")}
                index={
                  scoreWidget["count_last_day"]
                    ? scoreWidget["count_last_day"]
                    : 0
                }
                icon={qrScan}
              />
            }
          />
          <WidgetWrapper
            payedFeature={false}
            child={
              <ScoreWidget
                text={t("qrScansLatestX", { days: 7 })}
                index={
                  scoreWidget["count_last_7_days"]
                    ? scoreWidget["count_last_7_days"]
                    : 0
                }
                icon={qrScan}
              />
            }
          />
          <WidgetWrapper
            payedFeature={false}
            child={
              <ScoreWidget
                text={t("qrScansLatestX", { days: 30 })}
                index={
                  scoreWidget["count_last_30_days"]
                    ? scoreWidget["count_last_30_days"]
                    : 0
                }
                icon={qrScan}
              />
            }
          />
          <Box marginLeft={"auto"}>
            <WidgetWrapper
              payedFeature={false}
              child={
                <ScoreWidget
                  text={t("totalScans")}
                  index={qrSettings.scans ? qrSettings.scans : 0}
                  icon={qrScan}
                />
              }
            />
          </Box>
        </Flex>

        <GridItem>
          <WidgetWrapper
            callToAction={true}
            payedFeature={false}
            child={
              <Box w={"100%"} h={"100%"}>
                <Flex>
                  <Flex gap={3} padding={"0px 0px 20px 0px"}>
                    {timespan && (
                      <Select
                        value={timespan}
                        className="selector"
                        options={timespanOptions}
                        defaultValue={timespanOptions[0]}
                        onChange={(e) => {
                          setInterval(e.defaultValue);
                          setIntervalOptions(e.interval);
                          setTimespan(e);
                        }}
                      />
                    )}
                    {timespan && (
                      <Select
                        value={interval}
                        className="selector"
                        options={intervalOptions}
                        defaultValue={intervalOptions[0]}
                        onChange={(e) => {
                          setInterval(e);
                        }}
                      />
                    )}
                  </Flex>
                </Flex>
                {qrSettings && interval && timespan ? (
                  <HighChart
                    removeLegend={true}
                    selectedQR={[qrSettings]}
                    queryOptions={{
                      interval: interval.value,
                      timespan: timespan.value,
                    }}
                  />
                ) : (
                  <Flex
                    height={"400px"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    Välj en eller flera QR koder
                  </Flex>
                )}
              </Box>
            }
          />
        </GridItem>
      </Grid>
    </Grid>
  );
};

export default InfoQR;
