import { Flex, Box, Button, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { UserContext } from "../../context";
import { LockIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const WidgetWrapper = ({
  child,
  center,
  className,
  bg,
  noPadding,
  payedFeature,
  callToAction,
  qrLimit,
  extraTextComponoent,
  closeDown,
  closeDownText,
  blockForReadersOnly,
}) => {
  const [state] = useContext(UserContext);
  const navigation = useNavigate();

  if (
    (state?.organization?.role === "reader" && blockForReadersOnly) ||
    (payedFeature && !state.stripeRole) ||
    qrLimit ||
    closeDown
  ) {
    return (
      <Box position={"relative"}>
        <Flex
          position={"absolute"}
          w={"100%"}
          h={"100%"}
          bg={"white"}
          zIndex={40}
          rounded={"lg"}
          opacity={0.9}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          gap={3}
        >
          <LockIcon
            w={"10%"}
            h={"10%"}
            minWidth={5}
            minHeight={5}
            color="#292929"
          />
          {extraTextComponoent && extraTextComponoent}
          {closeDownText && (
            <Text color={"black"} fontSize={"14px"}>
              {closeDownText}
            </Text>
          )}
          {callToAction && (
            <Button
              fontSize={"14px"}
              onClick={() => navigation("/konto/betalning")}
            >
              {state?.organization?.role === "reader"
                ? "Ingen behörighet"
                : "Uppgradera ditt konto"}
            </Button>
          )}
        </Flex>
        <Flex
          height={"100%"}
          boxShadow={"lg"}
          className={className}
          justifyContent={center ? "center" : "left"}
          p={!noPadding && "20px 22px"}
          rounded={"lg"}
          bg={bg ? bg : "white"}
          color={"black"}
        >
          {child}
        </Flex>
      </Box>
    );
  } else {
    return (
      <Flex
        height={"100%"}
        boxShadow={"lg"}
        className={className}
        justifyContent={center ? "center" : "left"}
        p={!noPadding && "20px 22px"}
        rounded={"lg"}
        bg={bg ? bg : "white"}
        color={"black"}
      >
        {child}
      </Flex>
    );
  }
};

export default WidgetWrapper;
