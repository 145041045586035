import { Grid, Box, Flex, Button, Spinner } from "@chakra-ui/react";
import WidgetWrapper from "../../../../components/Widgets/WidgetWrapper";
import { InputSelector } from "../../../../components/Widgets/InputComponent";
import { saveAs } from "file-saver";
import QrGenerator from "../../../../components/QrGenerator";
import { QRCodeSVG, QRCodeCanvas } from "qrcode.react";
import { useTranslation } from "react-i18next";

const Download = ({
  qrSettings,
  setqrSettings,
  download,
  setDownload,
  loadImage,
}) => {
  const downloadFile = (type, logoImage) => {
    if (type === "png") {
      const canvas = document.getElementById("qrCodePNG");

      const pngDataUrl = canvas.toDataURL("image/png");

      // Trigger the download
      const link = document.createElement("a");
      link.href = pngDataUrl;
      link.download = `${qrSettings.qrName}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (type === "svg") {
      const svgElement = document.getElementById("qrCodeSVG");

      if (!svgElement) {
        return;
      }

      // Clone the SVG element to avoid modifying the original
      const clonedSvgElement = svgElement.cloneNode(true);

      // Find the image element within the cloned SVG
      const logo = clonedSvgElement.querySelector("image");

      if (logo) {
        // Replace this with the actual URL of your logo
        const logoImageUrl = logoImage;

        // Fetch the logo as a Blob
        fetch(logoImageUrl)
          .then((response) => response.blob())
          .then((logoBlob) => {
            // Convert the logo blob to a base64-encoded string
            const reader = new FileReader();
            reader.onload = function (event) {
              const base64Logo = event.target.result;

              // Set the base64-encoded logo as the xlink:href attribute
              logo.setAttribute("xlink:href", base64Logo);

              // Serialize the modified SVG element to XML string
              const svgXml = new XMLSerializer().serializeToString(
                clonedSvgElement
              );

              // Create a Blob containing the SVG data
              const svgBlob = new Blob([svgXml], { type: "image/svg+xml" });

              // Generate a unique name for the downloaded file
              const fileName = `${qrSettings.qrName}.svg`;

              // Save the Blob as a file using FileSaver.js
              saveAs(svgBlob, fileName);
            };

            // Read the logo blob as a data URL
            reader.readAsDataURL(logoBlob);
          })
          .catch((error) => console.error("Error fetching logo:", error));
      } else {
        // If there is no logo, directly serialize the SVG element and save it
        const svgXml = new XMLSerializer().serializeToString(clonedSvgElement);
        const svgBlob = new Blob([svgXml], { type: "image/svg+xml" });
        const fileName = `${qrSettings.qrName}.svg`;
        saveAs(svgBlob, fileName);
      }
    }
  };
  const { t } = useTranslation();

  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      color={"white"}
      gridTemplateColumns={"2fr 1fr"}
      gap={"20px"}
    >
      <Grid gap={"20px"} marginBottom={"auto"}>
        <WidgetWrapper
          child={
            <Box width={"100%"}>
              <InputSelector
                toolTip={true}
                toolTipText={t("formatTooltip")}
                label={t("formatLabel")}
                setState={setDownload}
                value={download}
                keyValue={"type"}
                options={[
                  { text: "PNG", value: "png" },
                  { text: "SVG", value: "svg" },
                ]}
              />
              {download.type === "png" && (
                <InputSelector
                  toolTip={true}
                  toolTipText={t("formatSize")}
                  label={t("size")}
                  setState={setDownload}
                  value={download}
                  keyValue={"size"}
                  options={[
                    { text: t("smallSize"), value: 1 },
                    { text: t("midSize"), value: 4 },
                    { text: t("bigSize"), value: 10 },
                  ]}
                />
              )}
              <Box marginTop={"20px"}>
                <Button
                  bg={"#5138ee"}
                  color={"white"}
                  onClick={() =>
                    downloadFile(download.type, qrSettings.logoImage)
                  }
                >
                  {t("downloadQR")}
                </Button>
              </Box>
            </Box>
          }
        />
      </Grid>
      <Grid gap={"20px"} marginBottom={"auto"}>
        <WidgetWrapper
          center={true}
          child={
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              {loadImage ? (
                <Flex alignItems={"center"} height={"230px"}>
                  <Spinner></Spinner>
                </Flex>
              ) : (
                <QrGenerator
                  loadImage={loadImage}
                  qrSettings={qrSettings}
                  setqrSettings={setqrSettings}
                />
              )}
            </Flex>
          }
        />
      </Grid>
      {download.size && download.type && (
        <Box hidden={true}>
          <QRCodeSVG
            id="qrCodeSVG"
            style={{ maxWidth: "100%" }}
            value={process.env.REACT_APP_PROXY_URL + "/" + qrSettings.qrID}
            bgColor={qrSettings.background}
            imageSettings={
              qrSettings.logoImage
                ? {
                    src: qrSettings.logoImage,
                    height: (qrSettings.logoHeight / 1.4) * download.size,
                    width: (qrSettings.logoWidth / 1.4) * download.size,
                  }
                : undefined
            }
            fgColor={qrSettings.color}
            level={qrSettings.ecLevel}
          />
          <QRCodeCanvas
            id="qrCodePNG"
            includeMargin={true}
            size={download.size * 200}
            style={{ maxWidth: "100%" }}
            value={process.env.REACT_APP_PROXY_URL + "/" + qrSettings.qrID}
            imageSettings={{
              src: qrSettings.logoImage,
              height: (qrSettings.logoHeight / 1.4) * download.size,
              width: (qrSettings.logoWidth / 1.4) * download.size,
            }}
            bgColor={qrSettings.background}
            fgColor={qrSettings.color}
            level={qrSettings.ecLevel}
          />
        </Box>
      )}
    </Grid>
  );
};

export default Download;
