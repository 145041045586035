import React, { useState, useContext, useEffect } from "react";
import Select from "react-select"; // Import react-select
import qr from "../content/icons/qr.svg";
import { UserContext } from "../context/index.js";
import { useTranslation } from "react-i18next";
import { setUserClaimLang } from "../calls.js";

import gb from "../content/flags/gb.svg";
import se from "../content/flags/se.svg";

const LanguageSelector = ({ loginPage }) => {
  const { i18n } = useTranslation();
  const [state] = useContext(UserContext);
  const [selectedLanguage, setSelectedLanguage] = useState("sv");
  const langCodes = ["en", "sv"];

  useEffect(() => {
    // Check URL parameters for lang
    const urlParams = new URLSearchParams(window.location.search);
    const languageFromUrl = urlParams.get("lng");
    let initialLanguage =
      languageFromUrl || sessionStorage.getItem("lng") || "sv"; // Default to "sv"

    if (!langCodes.includes(initialLanguage)) {
      initialLanguage = "sv"; // Fallback to "sv" if invalid
    }

    // Update the language state and session storage
    setSelectedLanguage(initialLanguage);
    sessionStorage.setItem("lng", initialLanguage);
    i18n.changeLanguage(initialLanguage);
  }, []); // Added dependencies

  const languages = [
    { code: "sv", name: "Svenska", icon: qr, flagSVG: se },
    { code: "en", name: "English", icon: qr, flagSVG: gb },
    // Add more languages with their respective icons
  ];

  // Map languages to react-select options
  const languageOptions = languages.map((language) => ({
    value: language.code,
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={language.flagSVG}
          alt={language.name}
          style={{
            height: 20,
            width: 20,
            marginRight: 8,
            borderRadius: "100%",
          }}
        />
        {language.name}
      </div>
    ),
  }));

  const handleLanguageChange = async (selectedOption) => {
    const newLanguage = selectedOption.value;
    setSelectedLanguage(newLanguage);
    sessionStorage.setItem("lng", newLanguage); // Save to session storage
    i18n.changeLanguage(newLanguage);

    // Update user claim language if not on login page
    if (!loginPage) {
      if (state.user) {
        // Check if user exists
        await setUserClaimLang(() => state.user.getIdToken(), newLanguage);
      } else {
        console.error("User is not defined in context");
      }
    }
  };

  return (
    <Select
      value={languageOptions.find(
        (option) => option.value === selectedLanguage
      )}
      onChange={handleLanguageChange}
      options={languageOptions}
      placeholder="Select language"
      isSearchable={false}
      styles={{
        // Custom styles for the dropdown
        control: (provided) => ({
          ...provided,
          minWidth: 200,
        }),
      }}
    />
  );
};

export default LanguageSelector;
