import { Box, Grid, GridItem, Flex, Button } from "@chakra-ui/react";
import WidgetWrapper from "../../components/Widgets/WidgetWrapper";
import { useState, useEffect } from "react";

import Select from "react-select";
import DataTablePrime from "../../components/DataTablePrime";

import HighChart from "../../functions/toHighChart";
import Options from "../../context/optionsChart";

import { useTranslation } from "react-i18next";

const Analys = () => {
  const { t } = useTranslation();

  const [selectedQR, setSelectedQR] = useState([]);
  const [runAnalysTool, setRunAnalysTool] = useState(false);
  const analysButton = true;

  const [timespan, setTimespan] = useState(false);
  const [interval, setInterval] = useState(false);
  const [timespanOptions, setTimeSpanOptions] = useState(false);
  const [intervalOptions, setIntervalOptions] = useState(false);

  useEffect(() => {
    setTimespan(Options(t)[0]);
    setInterval(Options(t)[0].interval[2]);

    setIntervalOptions(Options(t)[0].interval);
    setTimeSpanOptions(Options(t));
  }, [t]);

  const AnalyticTool = () => {
    return (
      <Grid gap={"20px"}>
        <GridItem>
          <WidgetWrapper
            payedFeature={false}
            child={
              <Box w={"100%"} h={"100%"}>
                <Flex>
                  <Flex gap={3} padding={"0px 0px 20px 0px"}>
                    {timespan && (
                      <Select
                        value={timespan}
                        className="selector"
                        options={timespanOptions}
                        defaultValue={timespanOptions[0]}
                        onChange={(e) => {
                          setInterval(e.defaultValue);
                          setIntervalOptions(e.interval);
                          setTimespan(e);
                        }}
                      />
                    )}
                    {timespan && (
                      <Select
                        value={interval}
                        className="selector"
                        options={intervalOptions}
                        defaultValue={intervalOptions[0]}
                        onChange={(e) => {
                          setInterval(e);
                        }}
                      />
                    )}
                  </Flex>
                </Flex>
                {selectedQR.length > 0 && interval && timespan ? (
                  <HighChart
                    selectedQR={selectedQR}
                    queryOptions={{
                      interval: interval.value,
                      timespan: timespan.value,
                    }}
                  />
                ) : (
                  <Flex
                    height={"400px"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {t("chooseOneOrMoreQR")}
                  </Flex>
                )}
              </Box>
            }
          />
        </GridItem>
      </Grid>
    );
  };

  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      w={"100%"}
      color={"white"}
      gap={"20px"}
      width={"100%"}
    >
      {runAnalysTool ? (
        <>
          <Flex>
            <Button
              bg={"#5138ee"}
              color={"white"}
              marginRight={"auto"}
              fontSize={"14px"}
              onClick={() => setRunAnalysTool(false)}
            >
              {t("back")}
            </Button>
          </Flex>

          <AnalyticTool />
        </>
      ) : (
        <Flex
          width={"100%"}
          rounded={"lg"}
          flexDirection={"column"}
          gap={"10px"}
        >
          <WidgetWrapper
            noPadding={true}
            payedFeature={false}
            callToAction={true}
            child={
              <Box width={"100%"}>
                <DataTablePrime
                  setRunAnalysTool={setRunAnalysTool}
                  analysButton={analysButton}
                  row={8}
                  noRedirect={true}
                  selectBox={true}
                  selectedQR={selectedQR}
                  setSelectedQR={setSelectedQR}
                />
              </Box>
            }
          ></WidgetWrapper>
        </Flex>
      )}
    </Grid>
  );
};

export default Analys;
