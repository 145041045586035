import { Text, Flex, Image } from "@chakra-ui/react";

const ScoreWidget = ({ text, index, icon }) => {
  return (
    <Flex gap={"20px"} width={"100%"} alignItems={"center"} color={"#292929"}>
      <Image h={"40px"} w={"40px"} src={icon} />
      <Flex flexDirection={"column"}>
        <Text fontSize={"20px"} fontWeight={"bold"}>
          {index}
        </Text>
        <Text fontWeight={"light"} fontSize={"14px"}>
          {text}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ScoreWidget;
