import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  Flex,
  List,
  ListItem,
  ListIcon,
  Heading,
  Spinner,
  Input,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { createCheckoutSession } from "../stripe/createCheckoutSession"; // If not used, consider removing
import WidgetWrapper from "./Widgets/WidgetWrapper";
import { getUserCheckoutLink } from "../calls"; // Assuming getUserBillingPortalLink is not used, consider removing
import { useTranslation } from "react-i18next";

const PayPlanCard = ({ child, subscriptionTime, state, userProductID }) => {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(1);

  const calculateDiscountPrice = (currentPrice, discountPercentage) => {
    const discountFactor = 1 - discountPercentage / 100;
    const originalPrice = currentPrice / discountFactor;
    return Math.floor(originalPrice - currentPrice);
  };

  const renderPriceDetails = () => {
    const priceData = child.prices?.[subscriptionTime];
    if (!priceData || !priceData.metadata.discount) return null;

    const priceConvert = priceData.unit_amount / 100;
    const discountPercentage = priceData.metadata.discount;
    const saveAmount = calculateDiscountPrice(priceConvert, discountPercentage);

    return (
      <Flex
        position="absolute"
        right="10px"
        gap="10px"
        top="10px"
        fontSize="12px"
      >
        <BoxDetails label={`${t("save")} ${saveAmount}kr`} />
        <BoxDetails label={`-${discountPercentage}%`} />
      </Flex>
    );
  };

  const BoxDetails = ({ label }) => (
    <Box
      bg="#5138EE"
      color="white"
      fontWeight="bold"
      borderRadius="lg"
      px="5px"
      py="5px"
    >
      {label}
    </Box>
  );

  const calculatePrice = (price, quantity) => {
    return price * (parseInt(quantity) || 1);
  };

  const renderPrice = () => {
    const priceData = child.prices?.[subscriptionTime];
    if (!priceData || !priceData.unit_amount) return <Box my={3}></Box>;

    const originalPrice = priceData.unit_amount_decimal / 100;

    return (
      <Text my={3} fontSize="32px" fontWeight="bold">
        {calculatePrice(originalPrice, quantity)}
        <span style={{ fontSize: "12px" }}>kr/st</span>
      </Text>
    );
  };

  const renderFeatures = () =>
    child.features.map((feature, index) => (
      <ListItem key={index} fontSize="14px">
        <ListIcon color="#5138EE" as={CheckCircleIcon} />
        {feature.name}
      </ListItem>
    ));

  const renderActionButton = () => (
    <Button
      mt={4}
      width="100%"
      p={4}
      bg="#5138EE"
      color="white"
      fontWeight="bold"
      onClick={handleActionClick}
    >
      {t("buy")}
    </Button>
  );

  const handleActionClick = async () => {
    if (child.prices) {
      try {
        const { data } = await getUserCheckoutLink(
          () => state.user.getIdToken(),
          {
            success_url: `${window.location.origin}/konto`,
            cancel_url: `${window.location.origin}/konto/betalning`,
            line_items: [
              {
                price: child.prices[subscriptionTime].id,
                quantity,
              },
            ],
          }
        );
        window.location.href = data.url;
      } catch (error) {
        console.error(error);
      }
    } else {
      // Handle case when there are no prices
    }
  };

  return (
    <Box flex={1} minW="350px" position="relative">
      <WidgetWrapper
        child={
          <Flex flex={1} direction="column">
            {renderPriceDetails()}
            <Heading as="h3" mt={5} fontSize="lg" color={child.textColor}>
              {child.name}
            </Heading>
            <Text mt={2} fontSize="sm" color={child.textColor}>
              {child.description}
            </Text>
            <Flex
              flexDirection="column"
              role="list"
              fontSize="sm"
              spacing={6}
              height="100%"
            >
              {renderPrice()}
              <Input
                type="number"
                onChange={(e) => setQuantity(e.target.value)}
                placeholder={t("quantity")}
                min={1}
              />
              <List mt="auto" flex={1} role="list" fontSize="sm" spacing={6}>
                {renderFeatures()}
              </List>
            </Flex>
            {renderActionButton()}
          </Flex>
        }
      />
    </Box>
  );
};

export default PayPlanCard;
