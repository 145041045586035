export function areAllValuesSet(settings) {
  for (const key in settings) {
    if (
      key === "orgID" ||
      key === "logoImage" ||
      key === "logoName" ||
      key === "logoWidth" ||
      key === "logoHeight"
    ) {
    } else if (
      settings[key] === "" ||
      settings[key] === null ||
      settings[key] === undefined
    ) {
      return false;
    }
  }
  return true;
}
