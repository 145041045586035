import { Flex } from "@chakra-ui/react";

import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primeflex/primeflex.css"; // css utility
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";

import DataTablePrime from "../../../components/DataTablePrime";

export default function AdvancedFilterDemo() {
  return (
    <Flex width={"100%"} rounded={"lg"} flexDirection={"column"} gap={"10px"}>
      <DataTablePrime row={8} />
    </Flex>
  );
}
