import {
  Flex,
  Text,
  Button,
  Divider,
  Center,
  Input,
  Link,
  Spinner,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Image,
  Box,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";

import { useContext, useEffect, useState } from "react";
import { loginWithEmailAndPassword } from "./index";
import { UserContext } from "../../context/index";
import { toast } from "react-toastify";

import LoginWithSocial from "./LoginWithSocial";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import ProfileIcon from "../../content/icons/profile.svg";
import { LockIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../components/LanguageSelector";

const Login = () => {
  const { t } = useTranslation();

  const navigator = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [removeGoogle, setRemoveGoole] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useContext(UserContext);

  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    setEmail(location?.state?.email ? location.state.email : "");
    setPassword(location?.state?.password ? location.state.password : "");
    setRemoveGoole(
      location?.state?.removeGoogle ? location.state.removeGoogle : false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex p={6} width={"100%"}>
      <Box
        position={"absolute"}
        top={0}
        right={0}
        margin={"20px"}
        background={"white"}
      >
        <LanguageSelector loginPage={true} />
      </Box>
      <Flex flexDirection={"column"} gap={"20px"} width={"100%"}>
        <Flex gap={"12px"} flexDirection={"column"}>
          <Text color={"white"} fontWeight={"bold"} fontSize={"18px"}>
            {t("welcomeText")}
          </Text>
          <Text color={"white"} fontSize={"14px"}>
            {t("welcomeSubtext")}
          </Text>
          <Center w={["100%"]}>
            <Divider />
          </Center>
        </Flex>
        <form
          style={{ width: "100%" }}
          onSubmit={async (e) => {
            e.preventDefault(); // Prevents the default form submission behavior
            setLoading(true);
            const data = await loginWithEmailAndPassword(email, password);
            if (data.status === 200) {
              const userResult = await data.user.getIdTokenResult();
              dispatch({
                ...state,
                user: data.user,
                plan: userResult.claims.plan,
              });
              //Check if its the same day the account was created, then we send to skapa-qr else dashboard
              const timestamp = parseInt(data.user.metadata.createdAt, 10);
              const createdAtDate = new Date(timestamp);

              // Get current date
              const currentDate = new Date();

              // Check if they are on the same day
              const isSameDay =
                createdAtDate.toDateString() === currentDate.toDateString();

              if (isSameDay) {
                navigator("/skapa-qr");
              } else {
                navigator("/dashboard");
              }
            } else if (data.status === 400) {
              toast.error(data.message);
            }
            setLoading(false);
          }}
        >
          <Flex flexDirection={"column"} gap={"12px"}>
            <InputGroup size="md" width={"100%"}>
              <InputLeftElement
                pointerEvents="none"
                children={
                  <Image width="20px" height={"20px"} src={ProfileIcon} />
                }
              />
              <Input
                fontSize={"14px"}
                border={"1px solid black"}
                rounded={"lg"}
                placeholder={t("email")}
                type="email"
                bg={"white"}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                _placeholder={{
                  fontWeight: "bold",
                }}
              />
            </InputGroup>
            <InputGroup size="md" width={"100%"}>
              <InputLeftElement
                pointerEvents="none"
                children={<LockIcon color={"#1a202c"} />}
              />
              <InputRightElement
                onClick={() => setShowPassword(!showPassword)}
                cursor={"pointer"}
                children={showPassword ? <ViewIcon /> : <ViewOffIcon />}
              />
              <Input
                fontSize={"14px"}
                bg={"white"}
                border={"1px solid black"}
                rounded={"lg"}
                placeholder={t("password")}
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                _placeholder={{
                  fontWeight: "bold",
                }}
              />
            </InputGroup>
            <Link
              onClick={() => navigator("/login/reset")}
              fontWeight={"semibold"}
              marginLeft={"auto"}
              fontSize={"12px"}
              color={"white"}
            >
              {t("forgotPassword")}
            </Link>
            <Button
              type="submit" // This makes the button trigger the form submission
              fontSize={"16px"}
              rounded={"lg"}
              bg={"#5138EE"}
              color={"white"}
              marginTop={"10px"}
              p={6}
              transition={"0.1s"}
              _hover={{ fontSize: "17px" }}
            >
              {loading ? <Spinner /> : <Text>{t("login")}</Text>}
            </Button>
          </Flex>
        </form>
        <Flex gap={2} color={"#718096"} fontSize={"12px"}>
          <Text>{t("notRegistered")} </Text>
          <Link
            onClick={() => navigator("/login/create")}
            fontWeight={"bold"}
            color={"white"}
          >
            {t("createAccountHere")}
          </Link>
        </Flex>
        {!removeGoogle && <LoginWithSocial />}
      </Flex>
    </Flex>
  );
};

export default Login;
