import {
  Text,
  Flex,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Box,
  Textarea,
} from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/react";
import { InfoIcon, ViewIcon } from "@chakra-ui/icons";
import { useState, forwardRef } from "react";

const InputComponent = forwardRef(
  ({
    placeholder,
    label,
    setState,
    value,
    keyValue,
    type,
    readOnly,
    toolTip,
    toolTipText,
    showPassword,
    checkBox,
    underText,
    isError,
  }) => {
    const [showhidden, setShowhidden] = useState(false);
    const [isLabelOpen, setIsLabelOpen] = useState(false);

    return (
      <Flex justifyContent={"center"} flexDirection={"column"}>
        <Flex alignItems={"center"} padding={"10px 0px"}>
          <Flex flexDirection={"column"} width={"100%"}>
            <Flex width={"100%"}>
              <Text color={"#292929"} fontWeight={"semibold"}>
                {label}
              </Text>
              {toolTip && (
                <Flex marginLeft={"auto"} gap={2}>
                  {showPassword && (
                    <ViewIcon
                      cursor={"pointer"}
                      color={"#292929"}
                      onMouseDown={() => setShowhidden(true)}
                      onMouseUp={() => {
                        setShowhidden(false);
                      }}
                      onMouseLeave={() => {
                        setShowhidden(false);
                      }}
                    />
                  )}
                  <Tooltip
                    label={toolTipText}
                    fontSize="xs"
                    p={"10px"}
                    bg="#292929"
                    isOpen={isLabelOpen}
                  >
                    <InfoIcon
                      color={"#292929"}
                      onClick={() => setIsLabelOpen(!isLabelOpen)}
                      onMouseLeave={() => setIsLabelOpen(false)}
                      onMouseOver={() => setIsLabelOpen(true)}
                    />
                  </Tooltip>
                </Flex>
              )}
            </Flex>
            {underText && <Text>{underText}</Text>}
          </Flex>
        </Flex>
        <Flex gap={2}>
          <Input
            isInvalid={isError}
            readOnly={readOnly}
            type={showhidden ? "text" : type}
            value={keyValue ? value[keyValue] : value}
            onChange={(e) =>
              setState(
                keyValue
                  ? { ...value, [keyValue]: e.target.value }
                  : e.target.value
              )
            }
            color={"#292929"}
            placeholder={placeholder}
          />
          {checkBox && checkBox}
        </Flex>
      </Flex>
    );
  }
);

export default InputComponent;

const InputComponentTextField = ({
  placeholder,
  label,
  setState,
  value,
  keyValue,
  type,
  readOnly,
  toolTip,
  toolTipText,
  showPassword,
  checkBox,
  underText,
}) => {
  const [showhidden, setShowhidden] = useState(false);
  const [isLabelOpen, setIsLabelOpen] = useState(false);
  return (
    <Flex justifyContent={"center"} flexDirection={"column"}>
      <Flex alignItems={"center"} padding={"10px 0px"}>
        <Flex flexDirection={"column"} width={"100%"}>
          <Flex width={"100%"}>
            <Text color={"#292929"} fontWeight={"semibold"}>
              {label}
            </Text>
            {toolTip && (
              <Flex marginLeft={"auto"} gap={2}>
                {showPassword && (
                  <ViewIcon
                    cursor={"pointer"}
                    color={"#292929"}
                    onMouseDown={() => setShowhidden(true)}
                    onMouseUp={() => {
                      setShowhidden(false);
                    }}
                    onMouseLeave={() => {
                      setShowhidden(false);
                    }}
                  />
                )}
                <Tooltip
                  label={toolTipText}
                  fontSize="xs"
                  p={"10px"}
                  bg="#292929"
                  isOpen={isLabelOpen}
                >
                  <InfoIcon
                    color={"#292929"}
                    onClick={() => setIsLabelOpen(!isLabelOpen)}
                    onMouseLeave={() => setIsLabelOpen(false)}
                    onMouseOver={() => setIsLabelOpen(true)}
                  />
                </Tooltip>
              </Flex>
            )}
          </Flex>
          {underText && <Text>{underText}</Text>}
        </Flex>
      </Flex>
      <Flex gap={2}>
        <Textarea
          readOnly={readOnly}
          type={showhidden ? "text" : type}
          value={keyValue ? value[keyValue] : value}
          onChange={(e) =>
            setState(
              keyValue
                ? { ...value, [keyValue]: e.target.value }
                : e.target.value
            )
          }
          color={"#292929"}
          placeholder={placeholder}
        />
        {checkBox && checkBox}
      </Flex>
    </Flex>
  );
};

const InputNumber = ({
  defaultValue,
  min,
  max,
  label,
  setState,
  value,
  keyValue,
  toolTip,
  toolTipText,
}) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false);
  return (
    <Flex w={"110px"} justifyContent={"center"} flexDirection={"column"}>
      <Flex alignItems={"center"} padding={"10px 0px"}>
        <Text color={"#292929"} fontWeight={"semibold"}>
          {label}
        </Text>
        {toolTip && (
          <Box marginLeft={"auto"}>
            <Tooltip
              label={toolTipText}
              fontSize="xs"
              p={"10px"}
              bg="#292929"
              isOpen={isLabelOpen}
            >
              <InfoIcon
                color={"#292929"}
                onClick={() => setIsLabelOpen(!isLabelOpen)}
                onMouseLeave={() => setIsLabelOpen(false)}
                onMouseOver={() => setIsLabelOpen(true)}
              />
            </Tooltip>
          </Box>
        )}
      </Flex>
      <NumberInput
        color={"black"}
        defaultValue={defaultValue}
        min={min}
        max={max}
        value={value[keyValue]}
        onChange={(e) => setState({ ...value, [keyValue]: parseInt(e) })}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Flex>
  );
};

const InputSelector = ({
  placeholder,
  label,
  setState,
  value,
  keyValue,
  options,
  toolTip,
  toolTipText,
}) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false);

  return (
    <Flex justifyContent={"center"} flexDirection={"column"}>
      <Flex alignItems={"center"} padding={"10px 0px"}>
        <Text color={"#292929"} fontWeight={"semibold"}>
          {label}
        </Text>
        {toolTip && (
          <Box marginLeft={"auto"}>
            <Tooltip
              label={toolTipText}
              fontSize="xs"
              p={"10px"}
              bg="#292929"
              isOpen={isLabelOpen}
            >
              <InfoIcon
                color={"#292929"}
                onClick={() => setIsLabelOpen(!isLabelOpen)}
                onMouseLeave={() => setIsLabelOpen(false)}
                onMouseOver={() => setIsLabelOpen(true)}
              />
            </Tooltip>
          </Box>
        )}
      </Flex>
      <Select
        value={value[keyValue]}
        color={"#292929"}
        onChange={(e) => setState({ ...value, [keyValue]: e.target.value })}
      >
        {options.map((child, index) => {
          return (
            <option key={child.value + index} value={child.value}>
              {child.text}
            </option>
          );
        })}
      </Select>
    </Flex>
  );
};

export { InputComponent, InputNumber, InputSelector, InputComponentTextField };
