import {
  Flex,
  Text,
  Button,
  Divider,
  Center,
  Image,
  Input,
  Spinner,
  InputGroup,
  InputLeftElement,
  Box,
} from "@chakra-ui/react";
import { resetPassword } from "./index";
import { useState } from "react";
import { toast } from "react-toastify";
import ProfileIcon from "../../content/icons/profile.svg";

import LoginWithSocial from "./LoginWithSocial";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../components/LanguageSelector";

const Create = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);

  return (
    <Flex p={6} width={"100%"}>
      <Box
        position={"absolute"}
        top={0}
        right={0}
        margin={"20px"}
        background={"white"}
      >
        <LanguageSelector loginPage={true} />
      </Box>
      <Flex flexDirection={"column"} gap={"20px"} width={"100%"}>
        <Flex flexDirection={"column"} gap={"12px"}>
          <Text color={"white"} fontWeight={"bold"} fontSize={"18px"}>
            {t("forgotPassword")}
          </Text>
          <Text color={"white"} fontSize={"14px"}>
            {t("forgotPasswordSubText")}
          </Text>
          <Center w={["100%"]}>
            <Divider />
          </Center>
        </Flex>
        <form
          style={{ width: "100%" }}
          onSubmit={async (e) => {
            e.preventDefault(); // Prevents the default form submission behavior
            setLoading(true);
            const response = await resetPassword(email);
            if (response.status === 200) {
              toast.info(response.message);
            } else {
              toast.error(response.message);
            }
            setLoading(false);
          }}
        >
          <Flex flexDirection={"column"} gap={1} marginY={"10px"}>
            <InputGroup size="md" width={"100%"}>
              <InputLeftElement
                pointerEvents="none"
                children={
                  <Image width="20px" height={"20px"} src={ProfileIcon} />
                }
              />
              <Input
                fontSize={"14px"}
                bg={"white"}
                border={"1px solid black"}
                rounded={"lg"}
                placeholder={t("email")}
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                _placeholder={{
                  fontWeight: "bold",
                }}
              />
            </InputGroup>
          </Flex>
          <Button
            width={"100%"}
            fontSize={"16px"}
            rounded={"lg"}
            bg={"#5138EE"}
            color={"white"}
            p={6}
            type="submit"
          >
            {loading ? <Spinner /> : <Text> {t("resetPassword")} </Text>}
          </Button>
        </form>

        <LoginWithSocial />
      </Flex>
    </Flex>
  );
};

export default Create;
