export function isGoogleUser(user) {
  if (user) {
    // Assuming providerData is an array of objects with an 'id' property
    for (const provider of user.providerData) {
      if (provider.providerId.includes("google.com")) {
        return true;
      }
    }
  }
  return false;
}

export function isPasswordAndEmail(user) {
  if (user) {
    // Assuming providerData is an array of objects with an 'id' property
    for (const provider of user.providerData) {
      if (provider.providerId.includes("password")) {
        return true;
      }
    }
  }
  return false;
}
