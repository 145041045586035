import { Flex, Input } from "@chakra-ui/react";
import { InputComponent, InputSelector } from "../Widgets/InputComponent";
import { useTranslation } from "react-i18next";

const QrStyle = ({ setState, value }) => {
  const { t } = useTranslation();

  return (
    <Flex gap={3} flexWrap={"wrap"}>
      <Flex flexDirection={"column"}>
        <Flex gap={3} flexWrap={"wrap"}>
          <InputComponent
            placeholder={"#FFFFF"}
            label={t("backgroundColor")}
            setState={setState}
            value={value}
            keyValue={"background"}
          />
          <Input
            marginTop="auto"
            type="color"
            value={value.background}
            w={"70px"}
            onChange={(e) => setState({ ...value, background: e.target.value })}
          />
        </Flex>
        <Flex gap={3} flexWrap={"wrap"}>
          <InputComponent
            placeholder={"#FFFFF"}
            label={t("patternColor")}
            setState={setState}
            value={value}
            keyValue={"color"}
          />
          <Input
            marginTop="auto"
            type="color"
            value={value.color}
            w={"70px"}
            onChange={(e) => setState({ ...value, color: e.target.value })}
          />
        </Flex>
      </Flex>
      <Flex flexDirection={"column"}>
        <Flex gap={3}>
          <InputSelector
            toolTip={true}
            toolTipText={t("ECTooltip")}
            label={t("EC")}
            setState={setState}
            value={value}
            keyValue={"ecLevel"}
            options={[
              { text: t("smallSize"), value: "L" },
              { text: t("midSize"), value: "M" },
              { text: t("bigSize"), value: "H" },
            ]}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default QrStyle;
