import {
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
  Button,
  Box,
} from "@chakra-ui/react";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../context/index";
import { generateSignedUrl } from "../../calls";
import ImageDropzone from "../ImageDrop";

import { InfoIcon } from "@chakra-ui/icons";

const QRlogo = ({ setState, value }) => {
  const [state] = useContext(UserContext);
  const [imageScale, setImageScale] = useState({ w: 0, h: 0 });
  const [sliderValue, setSliderValue] = useState(30);

  useEffect(() => {
    if (value.logoImage) {
      const image = new Image();
      image.src = value.logoImage;

      image.onload = function () {
        const width = image.width;
        const height = image.height;

        const scale =
          height === width
            ? { w: 1, h: 1 }
            : height > width
            ? { w: 1, h: height / width }
            : { w: width / height, h: 1 };

        setImageScale(scale);
        setState({
          ...value,
          logoWidth: value.logoWidth ? value.logoWidth : 30 * scale.w,
          logoHeight: value.logoHeight ? value.logoHeight : 30 * scale.h,
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.logoImage]);

  const uploadImage = async (logoImage) => {
    try {
      const response = await generateSignedUrl(
        () => state.user.getIdToken(),
        logoImage
      );
      const signedUrl = response.data.data.url;
      const fileName = response.data.data.fileName;

      // Extract and parse headers from the signedUrl
      const url = new URL(signedUrl);
      const headersString = url.searchParams.get("headers");

      // Check if headersString is not null or undefined
      const headersObject = headersString ? JSON.parse(headersString) : {};

      // Convert the plain object to Headers
      const headers = new Headers();
      for (const [key, value] of Object.entries(headersObject)) {
        headers.append(key, value);
      }

      // Include Content-Type header
      headers.append("Content-Type", logoImage.type);

      const fetchOptions = {
        method: "PUT",
        headers,
        body: logoImage,
      };

      const fetchResponse = await fetch(signedUrl, fetchOptions);

      if (fetchResponse.ok) {
        const urlResponse = fetchResponse.url;
        const blob = URL.createObjectURL(logoImage);
        const image = new Image();
        image.src = blob;

        image.onload = function () {
          const width = image.width;
          const height = image.height;
          const scale =
            height === width
              ? { w: 1, h: 1 }
              : height > width
              ? { w: 1, h: height / width }
              : { w: width / height, h: 1 };

          setSliderValue(sliderValue ? sliderValue : 30);
          setImageScale(scale);
          setState({
            ...value,
            logoName: fileName,
            logoImage: blob,
            logoWidth: 30 * scale.w,
            logoHeight: 30 * scale.h,
          });
        };

        return { status: 200, data: urlResponse };
      } else {
        const error = await fetchResponse.text();
        console.error(error);
        return { status: fetchResponse.status, error };
      }
    } catch (error) {
      return { status: 400, error: error };
    }
  };

  return (
    <Flex
      gap={3}
      flexWrap={"wrap"}
      w={"100%"}
      h={"100%"}
      flexDirection={"column"}
    >
      {value.logoImage && (
        <Flex gap={6} flexDirection={"column"}>
          <Flex width={"100%"}>
            <Text color={"#292929"} fontWeight={"semibold"}>
              Storlek på loggan
            </Text>
            <Flex marginLeft={"auto"} gap={2}>
              <Tooltip
                label={"Här kan du ändra storleken på din valda logga."}
                fontSize="xs"
                p={"10px"}
                bg="#292929"
              >
                <InfoIcon color={"#292929"} />
              </Tooltip>
            </Flex>
          </Flex>

          <Slider
            min={10}
            max={100}
            value={sliderValue}
            defaultValue={30}
            aria-label="slider-ex-1"
            onChange={(e) => {
              setSliderValue(e);
              setState({
                ...value,
                logoWidth: Math.floor(imageScale.w * e),
                logoHeight: Math.floor(imageScale.h * e),
              });
            }}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Flex>
      )}
      <Flex height={"200px"}>
        {value.logoImage ? (
          <Box>
            <Button
              bg={"#5138EE"}
              color={"white"}
              onClick={() =>
                setState({ ...value, logoName: "", logoImage: "" })
              }
            >
              Ta bort logga
            </Button>
          </Box>
        ) : (
          <ImageDropzone uploadImage={uploadImage} />
        )}
      </Flex>
    </Flex>
  );
};

export default QRlogo;
