import React, { useState, useRef } from "react";
import {
  Flex,
  Text,
  Box,
  Image as ChakraImage,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { FiUpload } from "react-icons/fi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ImageDropzone = ({ uploadImage }) => {
  const { t } = useTranslation();

  const [isDragActive, setIsDragActive] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleDragEnter = () => {
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragActive(false);

    const files = Array.from(e.dataTransfer.files);

    if (files.length > 0) {
      const selectedFile = files[0];

      // Check if the selected file is an image
      if (
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png" ||
        selectedFile.type === "image/gif"
      ) {
        const blob = URL.createObjectURL(selectedFile);
        displayPreview(selectedFile);

        try {
          setSelectedFile({ file: selectedFile, blob: blob });
        } catch (error) {
          console.error("Image upload error:", error);
        }
      } else {
        toast.error(t("fileTypeError"));
        console.error("Invalid file type. Please select an image file.");
      }
    }
  };

  const handleInputChange = (e) => {
    const files = Array.from(e.target.files);

    if (files.length > 0) {
      const selectedFile = files[0];

      // Check if the selected file is an image
      if (
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png" ||
        selectedFile.type === "image/gif"
      ) {
        const blob = URL.createObjectURL(selectedFile);
        displayPreview(selectedFile);
        try {
          setSelectedFile({ file: selectedFile, blob: blob });
        } catch (error) {
          console.error("Image upload error:", error);
        }
      } else {
        toast.error(t("fileTypeError"));
        console.error("Invalid file type. Please select an image file.");
      }
    }
  };

  const displayPreview = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const preventDefault = (e) => {
    setIsDragActive(true);
    e.preventDefault();
  };

  return (
    <Box
      onDragEnter={handleDragEnter}
      onDragOver={preventDefault}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      border="2px dashed"
      borderRadius="md"
      p={8}
      textAlign="center"
      borderColor={isDragActive ? "blue.500" : "gray.200"}
      width={"100%"}
    >
      <Flex
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
      >
        <Flex direction="column" align="center">
          <input
            type="file"
            accept="image/jpeg, image/png, image/gif"
            onChange={handleInputChange}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
          {!previewImage ? (
            <FiUpload size={20} onClick={() => fileInputRef.current.click()} />
          ) : (
            <ChakraImage
              src={previewImage}
              alt="Preview"
              maxH="100px"
              maxW="100px"
              mt={4}
            />
          )}
          <Text mt={4} color={isDragActive ? "blue.500" : "gray.500"}>
            {isDragActive ? t("dropFileHere") : t("dragAndDropFile")}
            {!isDragActive && (
              <Text
                as="span"
                textDecoration="underline"
                cursor="pointer"
                onClick={() => fileInputRef.current.click()}
                fontWeight={"bold"}
              >
                {t("chooseFile")}
              </Text>
            )}
            <Text fontSize={"12px"}>(PNG, JPG, GIF)</Text>
          </Text>
        </Flex>
        {selectedFile && (
          <Button
            bg={"#5138EE"}
            padding={"10px"}
            color={"white"}
            margin={"10px 0px"}
            fontSize={"14px"}
            onClick={async () => {
              setLoading(true);
              await uploadImage(selectedFile.file);
              setPreviewImage();
              setSelectedFile();
              setLoading(false);
            }}
          >
            {!loading ? <Text>{t("uploadLogo")}</Text> : <Spinner></Spinner>}
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default ImageDropzone;
