import { Flex, Grid } from "@chakra-ui/react";
import EditQR from "./EditQR";
import StatsQR from "./StatsQR";
import InfoQR from "./InfoQR";
import Download from "./Download";
import AlertsQR from "./AlertsQR";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../../context/index.js";
import {
  SettingsIcon,
  CalendarIcon,
  DeleteIcon,
  EditIcon,
  DownloadIcon,
  BellIcon,
} from "@chakra-ui/icons";

import { Spinner } from "@chakra-ui/react";
import DeleteQR from "./DeleteQR";
import { useLocation } from "react-router-dom";

import { getQRbyID, getUserImage } from "../../../../calls";
import SystemBar from "../../../../components/SystemBar.jsx";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const SingleQR = () => {
  const { t } = useTranslation();

  const navbar = [
    {
      text: t("general"),
      redirect: "",
      extraCSSButton: { fontSize: "14px" },
      icon: {
        active: <SettingsIcon color={"black"} w={"20px"} h={"20px"} />,
        inActive: <SettingsIcon color={"white"} w={"20px"} h={"20px"} />,
      },
    },
    {
      text: t("download"),
      redirect: "ladda-ner",
      extraCSSButton: { fontSize: "14px" },
      icon: {
        active: <DownloadIcon color={"black"} w={"20px"} h={"20px"} />,
        inActive: <DownloadIcon color={"white"} w={"20px"} h={"20px"} />,
      },
    },
    {
      text: t("statistik"),
      redirect: "statistik",
      extraCSSButton: { fontSize: "14px" },
      icon: {
        active: <CalendarIcon color={"black"} w={"20px"} h={"20px"} />,
        inActive: <CalendarIcon color={"white"} w={"20px"} h={"20px"} />,
      },
    },
    {
      text: t("edit"),
      redirect: "redigera",
      extraCSSButton: { fontSize: "14px" },
      icon: {
        active: <EditIcon color={"black"} w={"20px"} h={"20px"} />,
        inActive: <EditIcon color={"white"} w={"20px"} h={"20px"} />,
      },
    },
    {
      text: t("smartPling"),
      redirect: "notiser",
      beta: true,
      newFeature: true,
      extraCSSButton: { fontSize: "14px" },
      icon: {
        active: <BellIcon color={"black"} w={"20px"} h={"20px"} />,
        inActive: <BellIcon color={"white"} w={"20px"} h={"20px"} />,
      },
    },
    {
      text: t("removeQr"),
      redirect: "radera",
      extraCSSContainer: { marginLeft: "auto" },
      extraCSSButton: {
        bg: "#ee3838",
        fontSize: "12px",
        marginLeft: "auto",
      },
      extraCSSText: { color: "white" },
      icon: {
        active: <DeleteIcon color={"white"} w={"20px"} h={"20px"} />,
        inActive: <DeleteIcon color={"white"} w={"20px"} h={"20px"} />,
      },
    },
  ];

  const [state] = useContext(UserContext);
  const location = useLocation();
  const [qrSettings, setqrSettings] = useState(false);
  const navigate = useNavigate();
  const { qrid } = useParams();
  const [download, setDownload] = useState({
    type: "png",
    size: 1,
  });
  const [loadImage, setLoadImage] = useState(false);

  const timeStampFormat = (time) => {
    const milliseconds =
      time._seconds * 1000 + Math.floor(time._nanoseconds / 1e6);
    const dateToString = new Date(milliseconds).toISOString().split("T")[0];
    return dateToString;
  };

  useEffect(() => {
    const fetchQR = async () => {
      try {
        const response = await getQRbyID(() => state.user.getIdToken(), qrid);
        const formattedCreatedAt = timeStampFormat(
          response.data.data.created_at
        );
        const qrSettingsData = {
          qrID: qrid,
          ...response.data.data,
          created_at: formattedCreatedAt,
          alert: {
            emails: [""],
            numbers: [""],
            description: "",
            ...response.data.data.alert,
          },
        };
        setqrSettings(qrSettingsData);
      } catch (error) {
        toast.error(t("fetchQRCodeError", { qrid: qrid }));
        navigate("/mina-qr");
      }
    };
    fetchQR();
  }, [qrid, state.user, location, navigate]);

  useEffect(() => {
    const fetchUserSignedUrl = async (logoName) => {
      if (!qrSettings.logoName || qrSettings.logoImage) {
        return;
      }
      setLoadImage(true);

      try {
        const response = await getUserImage(
          () => state.user.getIdToken(),
          logoName
        );
        const imageUrl = response.data.signedUrl;

        // Fetch the logo as a blob
        const logoBlob = await fetch(imageUrl).then((res) => res.blob());

        // Create a Blob URL from the blob
        const logoBlobUrl = URL.createObjectURL(logoBlob);

        setqrSettings({ ...qrSettings, logoImage: logoBlobUrl });
        if (download && !download.logoImage) {
          setDownload({ ...download, logoImage: logoBlobUrl });
        }
        setLoadImage(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserSignedUrl(qrSettings.logoName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrSettings.logoImage, qrSettings]);

  if (!qrSettings) {
    return (
      <Flex justifyContent={"center"} alignItems={"center"} height={"100%"}>
        <Spinner size={"xl"} color="#062C8E"></Spinner>
      </Flex>
    );
  }

  return (
    <Grid color={"white"} gap={"15px"} padding={"10px 0px"}>
      <Flex gap={"10px"} h={"fit-content"} fontSize={"14px"}>
        <SystemBar navbar={navbar} pathname={`/mina-qr/${qrid}`} />
      </Flex>
      <Grid gap={5}>
        {location.pathname === `/mina-qr/${qrid}` && (
          <InfoQR qrSettings={qrSettings} loadImage={loadImage} />
        )}
        {location.pathname === `/mina-qr/${qrid}/ladda-ner` && (
          <Download
            qrSettings={qrSettings}
            download={download}
            setDownload={setDownload}
            loadImage={loadImage}
          />
        )}
        {location.pathname === `/mina-qr/${qrid}/statistik` && (
          <StatsQR qrSettings={qrSettings} />
        )}
        {location.pathname === `/mina-qr/${qrid}/redigera` && (
          <EditQR
            qrSettings={qrSettings}
            setqrSettings={setqrSettings}
            loadImage={loadImage}
            setLoadImage={setLoadImage}
          />
        )}
        {location.pathname === `/mina-qr/${qrid}/notiser` && (
          <AlertsQR qrSettings={qrSettings} setqrSettings={setqrSettings} />
        )}
        {location.pathname === `/mina-qr/${qrid}/radera` && (
          <DeleteQR qrSettings={qrSettings} />
        )}
      </Grid>
    </Grid>
  );
};

export default SingleQR;
