import { Box, Grid, GridItem } from "@chakra-ui/react";
import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";

const Payment = () => {
  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      w={"100%"}
      color={"white"}
      gridTemplateColumns={"1fr"}
      gap={"20px"}
      width={"100%"}
      padding={"0px 0px 20px 0px"}
    >
      <Grid gap={"20px"} marginBottom={"auto"}>
        <GridItem>
          <WidgetWrapper
            className="backgroundGradient"
            child={<Box>Payment</Box>}
          />
        </GridItem>
      </Grid>
    </Grid>
  );
};

export default Payment;
