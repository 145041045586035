import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const urlParams = new URLSearchParams(window.location.search);
const languageFromUrl = urlParams.get("lng");

// Initialize i18next with plugins and configuration
i18n
  .use(HttpBackend) // Load translations using HTTP
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Pass the instance to react-i18next
  .init({
    fallbackLng: languageFromUrl || "sv", // Fallback language if the detected language is not available
    lng: "sv",
    backend: {
      loadPath: "/lang/{{lng}}.json", // Path to load translations from
    },
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;
