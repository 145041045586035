import React, { useState, useContext } from "react";
import { UserContext } from "../context/index";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Text,
} from "@chakra-ui/react";
import { toast } from "react-toastify";

import { sendTicket } from "../calls";
import { useTranslation } from "react-i18next";

const TicketForm = ({ title }) => {
  const { t } = useTranslation();

  const [state] = useContext(UserContext);

  const [formData, setFormData] = useState({
    subject: title ? title : "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await sendTicket(() => state.user.getIdToken(), formData);

    toast.success(t("ticketSuccess"));

    // Reset form data after submission
    setFormData({
      userID: "",
      subject: "",
      description: "",
    });
  };

  return (
    <Box width={"100%"}>
      <Heading as="h2" size="lg" mb={4}>
        {t("ContactUs")}
      </Heading>
      <Text mb="20px">{title ? t("bookFreeQrTalk") : t("ticketText")}</Text>
      <form onSubmit={handleSubmit}>
        <FormControl id="subject" mb={4}>
          <FormLabel>{t("subject")}</FormLabel>
          <Input
            type="text"
            name="subject"
            value={title ? t("bookMeeting") : formData.subject}
            onChange={handleChange}
            required
          />
        </FormControl>
        <FormControl id="description" mb={4}>
          <FormLabel>{t("description")}</FormLabel>
          <Textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows={6}
            required
          />
        </FormControl>
        <Button type="submit" colorScheme="blue">
          {t("send")}
        </Button>
      </form>
    </Box>
  );
};

export default TicketForm;
