import { Box, Grid, GridItem, Text, Divider, Link } from "@chakra-ui/react";
import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";

const Security = () => {
  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      w={"100%"}
      color={"white"}
      gridTemplateColumns={"1fr"}
      gap={"20px"}
      width={"100%"}
      padding={"0px 0px 20px 0px"}
    >
      <Grid gap={"20px"} marginBottom={"auto"}>
        <GridItem>
          <WidgetWrapper
            child={
              <Box>
                <Text fontSize="2xl" fontWeight="bold" mb={4}>
                  Säkerhetsåtgärder
                </Text>
                <Text>
                  På DinQR prioriterar vi säkerheten för din data och QR-koder.
                  Vår plattform använder robusta säkerhetsåtgärder, inklusive
                  användning av Firebase Authentication och molntjänster, för
                  att säkerställa konfidentialiteten och integriteten för din
                  information.
                </Text>
                <Divider my={4} />
                <Text fontSize="md" fontWeight="bold" mb={2}>
                  Firebase Authentication
                </Text>
                <Text>
                  Vi använder Firebase Authentication för att säkra
                  användarkonton och autentiseringsprocesser. Detta säkerställer
                  att endast auktoriserade användare har tillgång till sina
                  konton och relaterad data.
                </Text>
                <Text my={4} fontSize="md" fontWeight="bold" mb={2}>
                  Molnsäkerhet
                </Text>
                <Text>
                  Vår molninfrastruktur är byggd på säkra tjänster och
                  implementerar branschstandard-säkerhetsåtgärder. Det
                  inkluderar kryptering, åtkomstkontroller och regelbundna
                  säkerhetsrevisioner för att skydda din data som lagras i
                  molnet.
                </Text>
                <Text my={4} fontSize="md" fontWeight="bold" mb={2}>
                  QR-kodssäkerhet
                </Text>
                <Text>
                  Säkerheten för dina QR-koder är av högsta prioritet. Vi
                  använder krypteringstekniker för att skydda informationen
                  kodad i QR-koderna, vilket förhindrar obehörig åtkomst och
                  manipulation.
                </Text>
                <Text my={4} fontSize="md" fontWeight="bold" mb={2}>
                  Kontinuerlig Övervakning och Förbättring
                </Text>
                <Text>
                  Vi övervakar kontinuerligt våra säkerhetsprotokoll och
                  uppdaterar dem för att hantera nya hot. Din säkerhet är av
                  yttersta vikt för oss, och vi är dedikerade till att erbjuda
                  en säker och pålitlig plattform.
                </Text>
                <Text my={4} fontSize="md" fontWeight="bold" mb={2}>
                  Kontakta Oss
                </Text>
                <Text>
                  Om du har några säkerhetsrelaterade frågor eller funderingar,
                  är du välkommen att kontakta oss på{" "}
                  <Link>security@dinqr.se</Link>.
                </Text>
              </Box>
            }
          />
        </GridItem>
      </Grid>
    </Grid>
  );
};

export default Security;
