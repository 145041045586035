// Options.js

const Options = (t) => {
  return [
    {
      value: 7,
      label: t("qrScansLatestX", { days: 7 }),
      defaultValue: { value: "DAY", label: "Dag" },
      interval: [
        { value: "MINUTE", label: "Minut" },
        { value: "HOUR", label: "Timme" },
        { value: "DAY", label: "Dag" },
      ],
    },
    {
      value: 30,
      label: t("qrScansLatestX", { days: 30 }),
      defaultValue: { value: "WEEK", label: "Vecka" },
      interval: [
        { value: "HOUR", label: "Timme" },
        { value: "DAY", label: "Dag" },
      ],
    },
    {
      value: 30 * 6,
      label: t("qrScansLatestXMonth", { month: 6 }),
      defaultValue: { value: "MONTH", label: "Månad" },
      interval: [
        { value: "DAY", label: "Dag" },
        { value: "MONTH", label: "Månad" },
      ],
    },
    {
      value: 365,
      label: t("qrScansLatestXMonth", { month: 12 }),
      defaultValue: { value: "MONTH", label: "Månad" },
      interval: [
        { value: "DAY", label: "Dag" },
        { value: "MONTH", label: "Månad" },
      ],
    },
  ];
};

export default Options;
