import { Box, Button, Flex, Spinner } from "@chakra-ui/react";
import usePremiumStatus from "../../../stripe/usePremiumStatus";
import { auth } from "../../../init/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useState, useContext } from "react";
import PayPlanCard from "../../../components/PayPlanCard";
import { UserContext } from "../../../context";
import { useTranslation } from "react-i18next";

const Payplan = ({ userProductID, products }) => {
  const { t } = useTranslation();
  const [state] = useContext(UserContext);
  const [subscriptionTime, setSubscriptionTime] = useState("each");
  const [user] = useAuthState(auth);
  const userIsPremium = usePremiumStatus(user);

  return (
    <Box overflowX={"scroll"} height={"100%"}>
      {Array.isArray(products) && products.length > 0 ? (
        <>
          <Flex
            alignContent={"center"}
            width={"100%"}
            flexWrap={"wrap"}
            gap={4}
            maxW={"1800px"}
          >
            {products.map((child) =>
              child.active ? (
                <PayPlanCard
                  key={child.id} // Use unique ID
                  userProductID={userProductID}
                  child={child}
                  subscriptionTime={subscriptionTime}
                  userIsPremium={userIsPremium}
                  state={state}
                />
              ) : null
            )}
          </Flex>
        </>
      ) : (
        <Flex
          width={"100%"}
          flex={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Spinner size={"xl"} color={"#062C8E"} />
        </Flex>
      )}
    </Box>
  );
};

export default Payplan;
