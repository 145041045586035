import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";
import { Box, Heading, Text } from "@chakra-ui/react";

const GetStarted = () => {
  return (
    <Box>
      <WidgetWrapper
        child={
          <Box p={4}>
            <Heading fontSize={"22px"} as="h1" mb={4}>
              Börja skapa
            </Heading>

            {/* Steg 1: Skapa QR-kod */}
            <Box mb={8}>
              <Heading fontSize={"18px"} as="h2" mb={4}>
                Steg 1: Skapa QR-kod
              </Heading>
              <Text>
                För att skapa en QR-kod, gå till "Skapa QR" i huvudmenyn.
              </Text>
              <Text>
                Gratis användare kan ange ett QR-namn och en destination.
                Betalande användare har tillgång till fler alternativ.
              </Text>
              <Text>
                När QR-koden du har skrivit in all information du vill ha,
                klicka på "Skapa QR kod" knappen.
              </Text>
            </Box>

            {/* Steg 2: Ladda ner QR-kod */}
            <Box>
              <Heading fontSize={"18px"} as="h2" mb={4}>
                Steg 2: Ladda ner QR-kod
              </Heading>
              <Text>
                För att ladda ner QR-koden, gå till "Ladda ner" i menyn och välj
                det önskade formatet.
              </Text>
              <Text>
                Betalande användare kan också följa realtidsinformation om sina
                QR-koder och göra analyser efter en tid.
              </Text>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

export default GetStarted;
