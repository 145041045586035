export function timeToDate(timestampString) {
  var timestamp = parseInt(timestampString, 10);

  var date = new Date(timestamp);

  // Options for formatting the date
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  // Convert the date to a Swedish-formatted string
  var swedishDate = date.toLocaleDateString("sv-SE", options);

  return swedishDate;
}
