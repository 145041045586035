import React, { useState, useContext, useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import Select, { components } from "react-select";
import { UserContext } from "../../context/index.js";
import {
  getAllUserDesignTemplates,
  deleteUserDesignTemplate,
  getUserImage,
} from "../../calls.js";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const DesignTemplateSelector = ({ setState, value, setLoadImage }) => {
  const { t } = useTranslation();
  const [state] = useContext(UserContext);
  const [userInput, setUserInput] = useState("");
  const [, setDesignTemplates] = useState();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const fetchUserSignedUrl = async (logoName) => {
      if (!value.logoName || value.logoImage) {
        return;
      }
      setLoadImage(true);
      try {
        const response = await getUserImage(
          () => state.user.getIdToken(),
          logoName
        );
        const imageUrl = response.data.signedUrl;

        // Fetch the logo as a blob
        const logoBlob = await fetch(imageUrl).then((res) => res.blob());

        // Create a Blob URL from the blob
        const logoBlobUrl = URL.createObjectURL(logoBlob);

        setLoadImage(false);
        setState({ ...value, logoImage: logoBlobUrl });
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserSignedUrl(value.logoName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.logoImage, value]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllUserDesignTemplates(() =>
          state.user.getIdToken()
        );
        setDesignTemplates(response.data.data);

        const options = response.data.data.map((child) => {
          return { label: child.designName, value: child };
        });
        setOptions(options);
      } catch (error) {
        toast.error("Inga Designmallar kunde hämtas");
      }
    };
    fetchData();
  }, [state.user]);

  const handleTemplateChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setState({
      ...selectedOption.value,
      qrID: value.qrID,
      targetUrl: value.targetUrl ? value.targetUrl : "",
      qrName: value.qrName ? value.qrName : "",
      tags: selectedOption.value.tags ? selectedOption.value.tags : [],
    });
  };

  const handleInputChange = (newValue) => {
    setUserInput(newValue);
  };

  const DeleteOption = ({ label, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = async (e) => {
      e.stopPropagation();
      setIsLoading(true);

      try {
        await removeTagFromUser(props.value.designID);
        const updatedOptions = options.filter(
          (a) => a.value.designID !== props.value.designID
        );
        setOptions(updatedOptions);
      } catch (error) {
        console.error("Error deleting tag:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const removeTagFromUser = async (id) => {
      try {
        await deleteUserDesignTemplate(() => state.user.getIdToken(), id);
      } catch (error) {
        throw error;
      }
    };

    return (
      <components.Option {...props}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {label}
          <button onClick={handleDelete} disabled={isLoading}>
            {isLoading ? "Ta bort tag..." : "X"}
          </button>
        </div>
      </components.Option>
    );
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        Inga designmallar hittades.
      </components.NoOptionsMessage>
    );
  };

  return (
    <Flex
      position={"relative"}
      flexDirection={"column"}
      marginLeft={["0px", "0px", "auto"]}
      width={"200px"}
    >
      <Select
        className="selector"
        closeMenuOnSelect={true}
        inputValue={userInput}
        onInputChange={handleInputChange}
        options={options}
        isCreatable
        onChange={handleTemplateChange}
        value={selectedOption}
        placeholder={t("chooseTemplate...")}
        components={{
          Option: (props) => <DeleteOption {...props} />,
          NoOptionsMessage,
        }}
      />
    </Flex>
  );
};

export default DesignTemplateSelector;
