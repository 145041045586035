import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  useNavigate,
} from "react-router-dom";
import { Box, Grid, Flex, Image, Link } from "@chakra-ui/react";
import Dashboard from "./pages/System/Dashboard";
import CreateQR from "./pages/System/CreateQR/CreateQR.jsx";
import AllMyQR from "./pages/System/MyQR/AllMyQR";
import SingleQR from "./pages/System/MyQR/SingleQR/SingleQR";
import Profile from "./pages/System/Profile";
import Analys from "./pages/System/Analys";
import LanguageOption from "./components/LanguageSelector.jsx";
import Org from "./pages/System/Org";

import {
  Support,
  SecuritySupport,
  PaymentSupport,
  GetStartedSupport,
  GDPRSupport,
  FAQSupport,
  KontaktSupport,
  BokaSupport,
} from "./pages/System/Support/index.js";

import PrivateRoutesLayout from "./layouts/PrivateRoutes";

import Login from "./pages/Login/Login";
import Create from "./pages/Login/Create";
import ForgotPassword from "./pages/Login/ForgotPassword";
import Verify from "./pages/Login/Verify.jsx";
import Navbar from "./components/Navbar";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { useContext, useEffect, useRef } from "react";
import { UserContext } from "./context/index.js";
import dinqrLogo from "./content/dinqr-white.svg";
import { useTranslation } from "react-i18next";

const RouteChangeHandler = () => {
  const [state] = useContext(UserContext);
  const navigate = useNavigate();
  const hasLogged = useRef(false);

  useEffect(() => {
    //console.log("User state:", state.user); // Debug log
    const userId = state?.user?.uid || "";
    if (state.user && !hasLogged.current) {
      hasLogged.current = true;
      //console.log("Logging user activity..."); // Debug log
      axios
        .put(process.env.REACT_APP_LOGS, {
          targetUrl: window.location.href,
          action: "page-view",
          userID: userId,
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [state.user]);

  return null;
};

const RouterComponents = () => {
  const { t } = useTranslation();
  return (
    <Router>
      <RouteChangeHandler />
      <Routes>
        <Route
          path="/"
          element={<ChildrenComponentsLogin child={<Login />} />}
        />
        <Route
          path="/login"
          element={<ChildrenComponentsLogin child={<Login />} />}
        />
        <Route
          path="/login/create"
          element={<ChildrenComponentsLogin child={<Create />} />}
        />
        <Route
          path="/login/reset"
          element={<ChildrenComponentsLogin child={<ForgotPassword />} />}
        />
        <Route
          path="/login/verify"
          element={<ChildrenComponentsLogin child={<Verify />} />}
        />

        <Route element={<PrivateRoutesLayout />}>
          <Route
            path="/dashboard"
            element={
              <ChildrenComponentSystem
                title={"Dashboard"}
                child={<Dashboard />}
              />
            }
          />
          <Route
            path="/skapa-qr"
            element={
              <ChildrenComponentSystem
                title={t("createQR")}
                child={<CreateQR />}
              />
            }
          />
          <Route path="/mina-qr/*">
            <Route
              index
              element={
                <ChildrenComponentSystem
                  title={t("myQR")}
                  child={<AllMyQR />}
                />
              }
            />
            <Route
              path=":qrid/*"
              element={<ChildrenComponentSystem child={<SingleQR />} />}
            />
          </Route>
          <Route
            path="/analysera"
            element={
              <ChildrenComponentSystem
                title={t("analyse")}
                child={<Analys />}
              />
            }
          />
          <Route
            path="/konto/*"
            element={
              <ChildrenComponentSystem
                title={t("myAccount")}
                child={<Profile />}
              />
            }
          />
          <Route
            path="/support"
            element={
              <ChildrenComponentSystem title={"Support"} child={<Support />} />
            }
          />
          <Route
            path="/support/sakerhet"
            element={
              <ChildrenComponentSystem
                title={t("security")}
                child={<SecuritySupport />}
              />
            }
          />
          <Route
            path="/support/kontakt"
            element={
              <ChildrenComponentSystem
                title={t("ContactUs")}
                child={<KontaktSupport />}
              />
            }
          />
          <Route
            path="/support/betalning"
            element={
              <ChildrenComponentSystem
                title={t("payment")}
                child={<PaymentSupport />}
              />
            }
          />
          <Route
            path="/support/borja-skapa"
            element={
              <ChildrenComponentSystem
                title={t("startCreate")}
                child={<GetStartedSupport />}
              />
            }
          />
          <Route
            path="/support/gdpr"
            element={
              <ChildrenComponentSystem title={"GDPR"} child={<GDPRSupport />} />
            }
          />
          <Route
            path="/support/faq"
            element={
              <ChildrenComponentSystem title={"FAQ"} child={<FAQSupport />} />
            }
          />
          <Route
            path="/support/boka"
            element={
              <ChildrenComponentSystem
                title={t("book")}
                child={<BokaSupport />}
              />
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};

const ChildrenComponentSystem = ({ child, title }) => {
  const { qrid } = useParams();
  const [state] = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    //console.log("User state in ChildrenComponentSystem:", state.user); // Debug log
    if (!state.user) {
      //console.log("Navigating to login..."); // Debug log
      navigate("/"); // Navigate only if user is not defined
    }
  }, [state.user, navigate]);

  return (
    <>
      <Grid
        bg={"#ececec"}
        minHeight={"100vh"}
        zIndex={1}
        display={"grid"}
        gridTemplateRows={["50px 1fr", "50px 1fr", "1fr"]}
        gridTemplateColumns={["1fr", "1fr", "200px 5fr"]}
      >
        <Navbar />
        <Grid gridTemplateRows={["1fr", "1fr", "60px 1fr"]} maxHeight={"100vh"}>
          <Box
            display={["none", "none", "flex"]}
            bg={"white"}
            height={"100%"}
            width={"100%"}
            alignItems={"center"}
            padding={"20px"}
            fontSize={"16px"}
            fontWeight={"bold"}
            color={"#062C8D"}
          >
            {title && title}
            {qrid && t("myQR")}
            <Box marginLeft={"auto"}>
              <LanguageOption />
            </Box>
          </Box>
          <Box padding={["10px", "10px", "20px"]} overflowY={"scroll"}>
            <Box height={"100%"}>{state.user && child}</Box>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer zIndex={100} />
    </>
  );
};

const ChildrenComponentsLogin = ({ child }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box minHeight={"100vh"} zIndex={1}>
        <Box bg={"#040620"} position={"relative"} zIndex={2}>
          <Box position={"relative"}>
            <Flex
              minHeight={"100vh"}
              flexDirection={"column"}
              alignItems={"center"}
              p={["0px", "10px 20px", "50px"]}
            >
              <Link
                onClick={() => navigate("/")}
                fontWeight={"bold"}
                fontSize={"18px"}
                color={"#062C8E"}
                marginTop={"20px"}
              >
                <Image w={"80px"} src={dinqrLogo} />
              </Link>
              <Flex
                maxWidth={["100%", "100%", "500px"]}
                height={["100%", "100%", "100%"]}
                w={["100%", "100%", "100%"]}
                rounded={"lg"}
                shadow={"lg"}
              >
                {child}
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>
      <ToastContainer zIndex={100} />
    </>
  );
};

export default RouterComponents;
