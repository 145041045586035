import React, { useState, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { UserContext } from "../context";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { Box, Tooltip, Text, Flex, Button } from "@chakra-ui/react";
import { BellIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primeflex/primeflex.css"; // css utility
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";

export default function TableData({
  row,
  selectBox,
  selectedQR,
  setSelectedQR,
  stringAmountOnLink,
  analysButton,
  setRunAnalysTool,
}) {
  const [state] = useContext(UserContext);
  const [data, setData] = useState(null);
  const [filters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filterData, setFilterData] = useState([]);
  const { t } = useTranslation();

  const [hideCreatedAtColumn, setHideCreatedAtColumn] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      setHideCreatedAtColumn(isMobile);
    };

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Call the handleResize function once to set the initial state
    handleResize();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that the effect runs once after the initial render

  const navigator = useNavigate();

  const timeStampFormat = (time) => {
    const milliseconds =
      time._seconds * 1000 + Math.floor(time._nanoseconds / 1e6);
    const dateToString = new Date(milliseconds).toISOString().split("T")[0];
    return dateToString;
  };

  useEffect(() => {
    const fetchUser = async () => {
      await axios
        .get(process.env.REACT_APP_API_ENDPOINT + "/getQRsByUserID", {
          headers: { Authorization: await state.user.getIdToken() },
        })
        .then((response) => {
          const userData = response.data.data.map((item) => ({
            qrName: item.qrName,
            created_at: timeStampFormat(item.created_at),
            targetUrl: item.targetUrl,
            qrID: item.qrID,
            tags: item.tags ? item.tags : [],
            scans: item.scans ? item.scans : 0,
            alert: item.alert ? item.alert : false,
          }));
          setData([...userData]);
        });
    };
    fetchUser();
  }, [state.user]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value.toLowerCase();
    setGlobalFilterValue(value); // Update filter value as the user types

    // Split the input value into an array of individual strings
    const filterStrings = value.split(" ");

    // Filter data based on each string separately and combine the results
    let filteredData = data.filter((item) => {
      return filterStrings.every((filterString) => {
        return (
          (item.qrName && item.qrName.toLowerCase().includes(filterString)) ||
          (item.created_at &&
            item.created_at.toLowerCase().includes(filterString)) ||
          (item.targetUrl &&
            item.targetUrl.toLowerCase().includes(filterString)) ||
          (item.tags &&
            item.tags.some(
              (tag) =>
                tag.label && tag.label.toLowerCase().includes(filterString)
            ))
        );
      });
    });

    setFilterData(filteredData);
  };

  const renderHeader = () => {
    return (
      <Flex gap={"10px"} flexDirection={["column-reverse", "row", "row"]}>
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Sök"
          style={{ padding: "5px 10px" }}
        />
        {analysButton && (
          <Button
            onClick={() => setRunAnalysTool(true)}
            isDisabled={!selectedQR.length > 0}
            marginLeft={["0px", "0px", "auto"]}
            bg={"#5138ee"}
            color={"white"}
            fontSize={"14px"}
          >
            {t("startAnalysis")}
          </Button>
        )}
      </Flex>
    );
  };

  const header = renderHeader();

  const shortString = (rowData) => {
    const amount = stringAmountOnLink ? stringAmountOnLink : 20;
    const shortString = rowData.targetUrl.substring(0, amount);

    return (
      <Tooltip label={rowData.targetUrl} aria-label="name-tooltip">
        <Box as="span" cursor="pointer">
          {rowData.targetUrl.length > amount
            ? shortString + "..."
            : rowData.targetUrl}
        </Box>
      </Tooltip>
    );
  };

  const labelBody = (rowData) => {
    return (
      <Flex width={"120px"} flexWrap={"wrap"} gap={"5px"}>
        {rowData.tags.map((child) => {
          return (
            <Text bg={"#e6e5e5"} padding={"4px 6px"} fontSize={"12px"}>
              {child.label}
            </Text>
          );
        })}
      </Flex>
    );
  };

  const Icons = (rowData) => {
    return (
      <Flex flexWrap={"wrap"} gap={"5px"}>
        {rowData.alert.active && (
          <Flex
            rounded={"100%"}
            bg={"#292929"}
            width={"20px"}
            height={"20px"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Tooltip
              label={t("activeNotification")}
              fontSize="xs"
              p={"10px"}
              bg="#292929"
            >
              <BellIcon color={"white"} />
            </Tooltip>
          </Flex>
        )}
      </Flex>
    );
  };

  if (!data) return;

  return (
    <DataTable
      selection={selectedQR}
      onSelectionChange={(e) =>
        selectBox
          ? setSelectedQR(e.value)
          : navigator(`/mina-qr/${e.value.qrID}`)
      }
      stripedRows
      tableStyle={{
        overflow: "scroll",
        fontSize: "14px",
      }}
      value={globalFilterValue ? filterData : data}
      selectionMode={selectBox ? "checkbox" : "single"}
      paginator
      rows={row}
      rowsPerPageOptions={[8, 10, 25, 50, 100]}
      emptyMessage={t("noQRFound")}
      filters={filters}
      header={header}
    >
      {selectBox && (
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>
      )}
      <Column sortable field="qrName" header={t("qrName")}></Column>
      {!hideCreatedAtColumn && (
        <Column sortable field="created_at" header={t("created")}></Column>
      )}
      <Column
        sortable
        body={shortString}
        field="targetUrl"
        header={t("editQRLinkLabel")}
      ></Column>
      <Column
        body={labelBody}
        field="tags.tag"
        header={t("qrTagLabel")}
      ></Column>
      <Column sortable field="scans" header="Scans"></Column>
      <Column body={Icons} field="alert"></Column>
    </DataTable>
  );
}
